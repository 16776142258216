import React, { useState } from 'react';
import { Form, Checkbox, InputNumber, Select, Modal } from 'antd';
import { LockableField } from '../../LockableField/LockableField';

/**
 * Component to display and edit price and billing information for an item
 * @param {{
 *   activeItem: import('./types').Item;
 *   isNewItem: boolean;
 *   braintreePlan?: import('./types').BraintreePlan;
 *   braintreePlanLoading: boolean;
 * }} props
 */
export function PriceBilling({
  activeItem,
  isNewItem,
  braintreePlan,
  braintreePlanLoading
}) {
  const [priceEditable, setPriceEditable] = useState(false);
  const needsBraintreePlan =
    (activeItem.hasFreeTrial || activeItem.allowArb) &&
    !braintreePlanLoading &&
    !braintreePlan;
  const billingPeriodRequired = needsBraintreePlan && !activeItem.billingPeriod;

  const priceModalOnClick = () => {
    Modal.confirm({
      title: 'Price Change Notice',
      content: (
        <div>
          You're about to enable updating the price of a live item. Price
          changes will take place <i>immediately</i>
          <ul>
            <li> Please double-check your changes.</li>
            <li>Inform relevant teams of this update.</li>
            <li>
              Ensure sales page pricing module changes are synced with this
              change
            </li>
          </ul>
          <strong> Proceed with care.</strong>
        </div>
      ),
      onOk: () => {
        setPriceEditable(true);
      }
    });
  };

  return (
    <div className="item__field-sections">
      <Form.Item
        style={{ backgroundColor: isNewItem ? '#EFFD5F' : 'none' }}
        label={
          <LockableField
            locked={!priceEditable}
            onUnlock={priceModalOnClick}
            label="Price"
          />
        }
        name="price"
      >
        <InputNumber
          disabled={!priceEditable}
          formatter={value => `$${value}`}
        />
      </Form.Item>
      <Form.Item
        label={
          <span style={{ color: billingPeriodRequired ? 'red' : undefined }}>
            Billing Period Length
          </span>
        }
        name="billingPeriod"
        style={{
          backgroundColor: isNewItem ? '#EFFD5F' : 'none'
        }}
        required={billingPeriodRequired}
      >
        <Select
          disabled={braintreePlanLoading || braintreePlan}
          allowClear={!braintreePlan}
        >
          <Select.Option value={1}>1 Month</Select.Option>
          <Select.Option value={12}>1 Year</Select.Option>
          <Select.Option value={24}>2 Years</Select.Option>
          <Select.Option value={36}>3 Years</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="hasFreeTrial" valuePropName="checked">
        <Checkbox checked={activeItem.hasFreeTrial} disabled={!braintreePlan}>
          Has Free Trial?
        </Checkbox>
      </Form.Item>
      <Form.Item
        label="Free Trial Length (days):"
        name="freeTrialDuration"
        hidden={!activeItem.hasFreeTrial}
      >
        <InputNumber
          addonAfter={true}
          min={1}
          disabled={!activeItem.hasFreeTrial}
        />
      </Form.Item>
      <Form.Item name="allowArb" valuePropName="checked">
        <Checkbox
          checked={activeItem.allowArb}
          disabled={!braintreePlan || activeItem.hasOrderItems}
        >
          Auto Renews?
        </Checkbox>
      </Form.Item>
      {needsBraintreePlan ? (
        <Form.Item style={{ color: 'red' }}>
          Braintree plan required for items with trials or that auto renew.
        </Form.Item>
      ) : null}
    </div>
  );
}
