import React from 'react';
import { Form, Select } from 'antd';
import { useItemsCategories } from '../hooks/useItemsCategories';

/**
 * Component to display and edit category information for an item
 * @param {{
 *   isNewItem: boolean;
 * }} props
 */
export function CategoryField({ isNewItem }) {
  const itemsCategories = useItemsCategories();

  return (
    <Form.Item
      label="Category"
      name="category"
      style={{ backgroundColor: isNewItem ? '#EFFD5F' : 'none' }}
    >
      <Select>
        {itemsCategories?.map(({ label, value }, index) => (
          <Select.Option value={value} key={index}>
            {label}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}
