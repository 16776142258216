import { gql } from '@apollo/client';

const GET_COURSE_BANNERS = gql`
  query GetCourseBanners($courseId: UUID!) {
    bannerCourseBindingsList(condition: { courseId: $courseId }) {
      id
      banner {
        id
        enabled
        activatedAt
        expiredAt
        title
        subtitle
      }
    }
  }
`;

const CREATE_BANNER_MUTATION = gql`
  mutation createBanner(
    $bannerId: UUID!
    $bindingId: UUID!
    $courseId: UUID!
    $activatedAt: Datetime!
  ) {
    createBanner(
      input: {
        banner: {
          id: $bannerId
          activatedAt: $activatedAt
          enabled: false
          title: ""
        }
      }
    ) {
      banner {
        id
        activatedAt
        enabled
        expiredAt
        subtitle
        title
      }
    }
    createBannerCourseBinding(
      input: {
        bannerCourseBinding: {
          id: $bindingId
          bannerId: $bannerId
          courseId: $courseId
        }
      }
    ) {
      bannerCourseBinding {
        id
      }
    }
  }
`;

const UPDATE_BANNER_MUTATION = gql`
  mutation updateBanner(
    $id: UUID!
    $enabled: Boolean!
    $activatedAt: Datetime!
    $expiredAt: Datetime
    $title: String!
    $subtitle: String
  ) {
    updateBanner(
      input: {
        id: $id
        patch: {
          enabled: $enabled
          activatedAt: $activatedAt
          expiredAt: $expiredAt
          title: $title
          subtitle: $subtitle
        }
      }
    ) {
      banner {
        id
        enabled
        activatedAt
        expiredAt
        title
        subtitle
      }
    }
  }
`;

export { GET_COURSE_BANNERS, CREATE_BANNER_MUTATION, UPDATE_BANNER_MUTATION };
