import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { chain, groupBy, sortBy, uniq } from 'lodash';
import { Select, Tooltip, Typography, message } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';

import { chapterActions } from './ChapterQueries';
import { lectureActions } from './LectureQueries';
import { questionActions } from './QuestionQueries';

import './TagSelector.css';

const { Option } = Select;
const { Text } = Typography;

const resourceActionMap = {
  chapter: chapterActions,
  lecture: lectureActions,
  question: questionActions
};

export default function TagSelector({
  primaryItemId,
  primaryResourceType,

  onBindingItemRemove,
  onBindingItemAdd
}) {
  const [messageApi, contextHolder] = message.useMessage();
  const [primaryTagTypes, setPrimaryTagTypes] = useState([]);
  const [secondaryTagTypes, setSecondaryTagTypes] = useState([]);
  const [additionalTagTypes, setAdditionalTagTypes] = useState([]);
  const [currentTagBindings, setCurrentTagBindings] = useState([]);

  const resourceActions = resourceActionMap[primaryResourceType];

  const { data: itemTagsData, loading, error: dataError } = useQuery(
    resourceActions.get,
    {
      skip: !resourceActions,
      variables: { itemId: primaryItemId }
    }
  );

  const [createBindingMutation] = useMutation(resourceActions.create, {
    refetchQueries: [resourceActions.get]
  });
  const [deleteBindingMutation] = useMutation(resourceActions.delete, {
    refetchQueries: [resourceActions.get]
  });

  useEffect(() => {
    if (dataError) {
      messageApi.error('Error loading tags: ' + dataError.message);
    }
  }, [dataError, messageApi]);

  useEffect(() => {
    if (itemTagsData) {
      const selectedTags = resourceActions.getSelectedTags(itemTagsData);
      const courses = resourceActions.getCourses(itemTagsData);

      const selectedTagsByType = groupBy(
        selectedTags,
        binding => binding.tag.tagTypeId
      );

      const tagTypes = chain(courses)
        .flatMap((
          course // each course may have multiple tag types
        ) =>
          course.courseTagTypeBindingsList.map(binding => ({
            id: binding.tagType.id,
            course: course.title,
            isPrimary: binding.isPrimary,
            isSecondary: binding.isSecondary,
            name: binding.tagType.name,
            tags: binding.tagType.tagsList,
            selected:
              selectedTagsByType[binding.tagType.id]?.map(t => t.tag.id) || []
          }))
        )
        .groupBy(tagType => tagType.name) // tagTypes may be repeated across courses
        .values()
        // reduce each array of courses to a single object that lists
        // which courses each tag type is primary/secondary for
        .map(tagTypePerCourse => ({
          ...tagTypePerCourse[0],
          course: uniq(tagTypePerCourse.map(tagType => tagType.course)),
          isPrimary: tagTypePerCourse.reduce(
            (result, tagType) =>
              tagType.isPrimary ? [...result, tagType.course] : result,
            []
          ),
          isSecondary: tagTypePerCourse.reduce(
            (result, tagType) =>
              tagType.isSecondary ? [...result, tagType.course] : result,
            []
          )
        }))
        .value();

      // split into tag types that are primary for at least one course and those that are not primary for any courses
      // so we can show primary tag types first
      setPrimaryTagTypes(
        sortBy(
          tagTypes.filter(tagType => tagType.isPrimary.length),
          tagType => tagType.name
        )
      );
      setSecondaryTagTypes(
        sortBy(
          tagTypes.filter(
            tagType => !tagType.isPrimary.length && tagType.isSecondary.length
          ),
          tagType => tagType.name
        )
      );
      setAdditionalTagTypes(
        sortBy(
          tagTypes.filter(
            tagType => !tagType.isPrimary.length && !tagType.isSecondary.length
          ),
          tagType => tagType.name
        )
      );

      setCurrentTagBindings(selectedTags);
    }
  }, [resourceActions, itemTagsData, primaryResourceType]);

  const createItemBinding = async tagId => {
    try {
      await createBindingMutation({
        variables: {
          id: uuidv4(),
          createdAt: new Date(),
          updatedAt: new Date(),
          itemId: primaryItemId,
          tagId
        }
      });
      onBindingItemAdd && onBindingItemAdd(tagId);
    } catch (e) {
      messageApi.error('Error saving tag: ' + e.message);
    }
  };

  const deleteItemBinding = async tagId => {
    try {
      const bindingId = currentTagBindings.find(
        binding => binding.tag.id === tagId
      )?.id;

      if (!bindingId) {
        throw new Error('Tag Binding not found');
      }

      await deleteBindingMutation({
        variables: { id: bindingId }
      });
      onBindingItemRemove && onBindingItemRemove(tagId);
    } catch (e) {
      messageApi.error('Error deleting tag: ' + e.message);
    }
  };

  const getTitle = tagType => {
    let tooltip = 'This Tag Type is ';
    if (tagType.isPrimary.length) {
      tooltip += 'Primary for ' + tagType.isPrimary.join(', ');

      if (tagType.isSecondary.length) {
        tooltip += ' and ';
      }
    }
    if (tagType.isSecondary.length) {
      tooltip += 'Secondary for ' + tagType.isSecondary.join(', ');
    }
    return tooltip;
  };

  const tagTypeSelector = tagType => (
    <div key={tagType.id} className="tag-selector__group">
      <label className="tag-selector__label">
        {tagType.name} {tagType.isPrimary.length > 0 ? '(Primary) ' : ' '}
        {tagType.isPrimary.length || tagType.isSecondary.length ? (
          <Tooltip title={getTitle(tagType)}>
            <InfoCircleOutlined />
          </Tooltip>
        ) : null}
      </label>
      <Select
        mode="multiple"
        placeholder="Add tags"
        defaultValue={tagType.selected}
        className="tag-selector__selector"
        onSelect={createItemBinding}
        onDeselect={deleteItemBinding}
        optionFilterProp="title"
      >
        {tagType.tags.map(tag => (
          <Option key={tag.id} title={tag.title}>
            {tag.title}
          </Option>
        ))}
      </Select>
    </div>
  );

  return (
    <div className="tag-selector">
      {contextHolder}
      {loading ? (
        <></>
      ) : primaryTagTypes.length === 0 &&
        secondaryTagTypes.length === 0 &&
        additionalTagTypes.length === 0 ? (
        <Text type="warning">
          Please tie this chapter to a specific course before selecting tags
        </Text>
      ) : (
        <>
          {primaryTagTypes.map(tagTypeSelector)}
          {secondaryTagTypes.map(tagTypeSelector)}
          {additionalTagTypes.map(tagTypeSelector)}
        </>
      )}
    </div>
  );
}
