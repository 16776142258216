import React, { useEffect, useState, useCallback } from 'react';
import * as API from '../../API';
import { Descriptions, Select, Tooltip } from 'antd';
import { notify } from 'react-notify-toast';
import { InfoCircleFilled } from '@ant-design/icons';
import CourseDescriptionSection from '../../components/Course/CourseDescriptionSection';

export default function CourseSettingsTagSection({
  course,
  loadingCourseData,
  refetch
}) {
  const [courseTagTypeBindings, setCourseTagTypeBindings] = useState(null);
  const [primaryTagTypeId, setPrimaryTagTypeId] = useState(null);
  const [secondaryTagTypeIds, setSecondaryTagTypeIds] = useState([]);
  const [additionalTagTypeIds, setAdditionalTagTypeIds] = useState([]);
  const [tagTypes, setTagTypes] = useState(null);

  const loadData = useCallback(async course => {
    try {
      const primaryTagType = course.courseTagTypeBindingsList.find(
        courseTagTypeBinding => courseTagTypeBinding.isPrimary
      )?.tagType;
      const primaryTagTypeId = primaryTagType ? primaryTagType.id : null;
      const secondaryTagTypeIds = course.courseTagTypeBindingsList
        .filter(courseTagTypeBinding => courseTagTypeBinding.isSecondary)
        .map(courseTagTypeBinding => courseTagTypeBinding.tagType.id);
      const additionalTagTypeIds = course.courseTagTypeBindingsList
        .filter(
          courseTagTypeBinding =>
            !courseTagTypeBinding.isPrimary && !courseTagTypeBinding.isSecondary
        )
        .map(courseTagTypeBinding => courseTagTypeBinding.tagType.id);

      const tagTypes = await API.fetchAllPages(
        API.tagType.all({
          options: {
            sort: 'name'
          }
        })
      );

      setCourseTagTypeBindings(course.courseTagTypeBindingsList);
      setPrimaryTagTypeId(primaryTagTypeId);
      setSecondaryTagTypeIds(secondaryTagTypeIds);
      setAdditionalTagTypeIds(additionalTagTypeIds);
      setTagTypes(tagTypes);
    } catch (error) {
      if (error.data) {
        notify.show(error.data.errors[0].title, 'error');
      } else {
        throw error;
      }
    }
  }, []);

  useEffect(() => {
    if (course && !loadingCourseData) {
      loadData(course);
    }
  }, [course, loadData, loadingCourseData]);

  const deleteCourseTagTypeBinding = async tagTypeId => {
    const binding = courseTagTypeBindings.find(
      binding => binding.tagTypeId === tagTypeId
    );
    await API.courseTagTypeBinding.delete({ id: binding.id });

    const updatedCourseTagTypeBindings = courseTagTypeBindings.filter(
      tagTypeBinding => tagTypeBinding.id !== binding.id
    );
    const secondaryTagTypeIds = updatedCourseTagTypeBindings
      .filter(binding => binding.isSecondary)
      .map(binding => binding.tagTypeId);
    const additionalTagTypeIds = updatedCourseTagTypeBindings
      .filter(binding => !binding.isPrimary && !binding.isSecondary)
      .map(binding => binding.tagTypeId);

    setCourseTagTypeBindings(updatedCourseTagTypeBindings);
    setSecondaryTagTypeIds(secondaryTagTypeIds);
    setAdditionalTagTypeIds(additionalTagTypeIds);
  };

  const createCourseTagTypeBinding = async (
    tagTypeId,
    isPrimary,
    isSecondary
  ) => {
    await API.courseTagTypeBinding.create({
      attributes: {
        isPrimary,
        isSecondary
      },
      relationships: {
        course: {
          data: {
            type: 'course',
            id: course.id
          }
        },
        tagType: {
          data: {
            type: 'tagType',
            id: tagTypeId
          }
        }
      }
    });

    const { data: courseData } = await refetch();

    if (isPrimary) {
      setPrimaryTagTypeId(tagTypeId);
    } else if (isSecondary) {
      const updatedSecondaryTagTypeIds = [...secondaryTagTypeIds, tagTypeId];
      setSecondaryTagTypeIds(updatedSecondaryTagTypeIds);
    } else {
      const updatedAdditionalTagTypeIds = [...additionalTagTypeIds, tagTypeId];
      setAdditionalTagTypeIds(updatedAdditionalTagTypeIds);
    }
    setCourseTagTypeBindings(courseData.course.courseTagTypeBindingsList);
  };

  const handlePrimaryTagSelect = async value => {
    // deselect previous tag type
    if (primaryTagTypeId) {
      await deleteCourseTagTypeBinding(primaryTagTypeId);
    }
    await createCourseTagTypeBinding(value, true);
  };

  const handleTagChange = async (value, isSecondary) => {
    const currentTags = isSecondary
      ? secondaryTagTypeIds
      : additionalTagTypeIds;

    if (value.length < currentTags.length) {
      // A secondary tag was removed. Delete the tag
      const tagTypeIdToRemove = currentTags.find(
        tagTypeId => !value.includes(tagTypeId)
      );
      await deleteCourseTagTypeBinding(tagTypeIdToRemove);
    } else {
      // A secondary tag was added. Add the tag and binding.
      const tagTypeIdToAdd = value.find(
        tagTypeId => !currentTags.includes(tagTypeId)
      );
      await createCourseTagTypeBinding(tagTypeIdToAdd, false, isSecondary);
    }
  };

  const shouldDisableTagType = (tagTypeId, isPrimary, isSecondary) =>
    courseTagTypeBindings.find(
      binding =>
        binding.tagTypeId === tagTypeId &&
        (binding.isPrimary !== isPrimary || binding.isSecondary !== isSecondary)
    );

  return (
    <CourseDescriptionSection title="Tags">
      {courseTagTypeBindings && tagTypes?.length && (
        <>
          <Descriptions.Item
            label={
              <div className="course__tag-system-label">
                <div>Primary Tag System</div>
                <Tooltip
                  title="The primary tag system will organize the user-facing content as the main topics and icons.
                Note: if this is not a review product, select Hippo tag"
                >
                  <InfoCircleFilled className="course__tag-system-label-icon" />
                </Tooltip>
              </div>
            }
          >
            <Select
              defaultValue={
                primaryTagTypeId
                  ? primaryTagTypeId
                  : 'Select Primary Tag System'
              }
              allowClear={true}
              onChange={value => handlePrimaryTagSelect(value)}
              className="course__select"
            >
              {tagTypes &&
                tagTypes.map(type => (
                  <Select.Option
                    key={type.id}
                    disabled={shouldDisableTagType(type.id, true)}
                  >
                    {type.attributes.name}
                  </Select.Option>
                ))}
            </Select>
          </Descriptions.Item>
          <Descriptions.Item
            label={
              <div className="course__tag-system-label">
                <div>Secondary Tag System</div>
                <Tooltip title="Expand the available tag selection for any content in this course to more tag systems, e.g. for searching or reporting purposes (usage varies by course and course type)">
                  <InfoCircleFilled className="course__tag-system-label-icon" />
                </Tooltip>
              </div>
            }
          >
            <Select
              onChange={value => handleTagChange(value, true)}
              defaultValue={secondaryTagTypeIds}
              mode="multiple"
              placeholder="Select Secondary Tag System"
              className="course__select"
            >
              {tagTypes &&
                tagTypes.map(type => (
                  <Select.Option
                    key={type.id}
                    disabled={shouldDisableTagType(type.id, false, true)}
                  >
                    {type.attributes.name}
                  </Select.Option>
                ))}
            </Select>
          </Descriptions.Item>
          <Descriptions.Item
            label={
              <div className="course__tag-system-label">
                <div>Additional Tag System</div>
                <Tooltip title="Additional Tag systems are used for internal organizational purposes only.">
                  <InfoCircleFilled className="course__tag-system-label-icon" />
                </Tooltip>
              </div>
            }
          >
            <Select
              onChange={value => handleTagChange(value, false)}
              defaultValue={additionalTagTypeIds}
              mode="multiple"
              placeholder="Select Additional Tag System"
              className="course__select"
            >
              {tagTypes &&
                tagTypes.map(type => (
                  <Select.Option
                    key={type.id}
                    disabled={shouldDisableTagType(type.id, false, false)}
                  >
                    {type.attributes.name}
                  </Select.Option>
                ))}
            </Select>
          </Descriptions.Item>
        </>
      )}
    </CourseDescriptionSection>
  );
}
