import React, { useState } from 'react';
import { Form, Input, Modal } from 'antd';
import { LockableField } from '../../LockableField/LockableField';

/**
 * Component to display and edit SKU information for an item
 * @param {{
 *   isNewItem: boolean;
 *   isNewItemSku: boolean;
 * }} props
 */
export function SkuField({ isNewItem, isNewItemSku }) {
  const [skuEditable, setSkuEditable] = useState(false);

  const skuModalOnClick = () => {
    Modal.confirm({
      title: 'SKU Change Notice',
      content: (
        <div>
          You're about to enable updating the SKU of this new item.{' '}
          <i>It can only be updated once.</i>
          <strong> Proceed with care.</strong>
        </div>
      ),
      onOk: () => setSkuEditable(true)
    });
  };

  return (
    <Form.Item
      style={{ backgroundColor: isNewItem ? '#EFFD5F' : 'none' }}
      label={
        <LockableField
          locked={isNewItemSku && !skuEditable}
          onUnlock={skuModalOnClick}
          label="SKU"
        />
      }
      name="sku"
      tooltip={
        !isNewItemSku && (
          <span>
            This is the SKU for the item & is not editable to prevent mishaps
          </span>
        )
      }
    >
      <Input disabled={!isNewItemSku || !skuEditable} />
    </Form.Item>
  );
}
