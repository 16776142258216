/* eslint-disable no-useless-escape */
import _ from 'underscore';
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { notify } from 'react-notify-toast';
import {
  PlusOutlined,
  CalculatorOutlined,
  CloseSquareOutlined,
  CopyOutlined
} from '@ant-design/icons';
import { Button, DatePicker, Card, Select, Tooltip } from 'antd';
import { Label } from 'reactstrap';
import { ApolloConsumer } from '@apollo/client';

import config from '../../config';
import * as API from '../../API';
import BreadcrumbConstants from '../../routes/BreadcrumbConstants';
import EditableLabel from '../../components/EditableLabel/EditableLabel';
import RichTextEditor from '../../components/RichTextEditor';
import SortableList from '../../components/SortableList';
import SingleImageInput from '../../components/Upload/SingleImageInput';
import { ResourceSelectorModal } from '../../components/ResourceSelectorModal';
import AudioUpload from '../../components/Upload/AudioUpload';
import ChapterListItem from '../../components/ChapterListItem/ChapterListItem';
import ChapterListItemHeader from '../../components/ChapterListItem/ChapterListItemHeader';
import FillMainView from '../../components/FillMainView';
import Chapter from './Chapter';

import './Episode.css';

const getChapterBindings = async episodeId => {
  const chapterBindings = await API.episodeChapterBinding.where({
    filter: { episode: episodeId },
    options: {
      sort: 'displayOrder',
      include:
        'chapter,chapter.chapterFacultyBindings.faculty,chapter.chapterEditorBindings.faculty,chapter.chapterTagBindings.tag,chapter.media'
    }
  });
  return API.simplifyResource(chapterBindings);
};

class Episode extends React.Component {
  static propTypes = {
    getSlug: PropTypes.func,
    onChange: PropTypes.func,
    episode: PropTypes.shape({
      id: PropTypes.string,
      attributes: PropTypes.shape({}),
      relationships: PropTypes.shape({}),
      links: PropTypes.shape({})
    })
  };

  static defaultProps = {
    episode: undefined,
    courseList: []
  };

  constructor(props) {
    super(props);

    this.state = {
      showAddChapter: false,
      playlistTypes: [],
      selectedPlaylistType: props.episode.relationships.playlistType.data.id,
      nextEpisodeNumber: null,
      lastMonthlyEpisode: null,
      chapterBindings: null,
      chapters: null,
      chapterFilter: { text: '', onlyUnattached: true },
      loadingChapters: false,
      error: null,
      editingChapter: null
    };
  }

  async componentDidMount() {
    await this.loadChapterBindings();
    await this.loadEpisodeArtwork();
    await this.loadPlaylistTypes();
    await this.loadNextEpisodeNumber();
    await this.setInitialEditingChapter();
  }

  setInitialEditingChapter = async () => {
    const { chapterId } = this.props.match.params;
    if (chapterId) {
      this.setState({ editingChapter: chapterId });
    }
  };

  loadNextEpisodeNumber = async () => {
    const filter = {
      playlistType: this.state.playlistTypes.find(
        type => type.attributes.shortname === 'monthly-playlist'
      ).id,
      course: this.props.episode.relationships.course.data.id,
      episodeNumber: '>0'
    };
    const lastNumberedEspisode = await API.episode.where({
      filter,
      options: { sort: '-episodeNumber', 'page[limit]': 1 }
    });

    let nextEpisodeNumber = 1;
    let lastMonthlyEpisode = null;
    if (lastNumberedEspisode && lastNumberedEspisode.data[0]) {
      lastMonthlyEpisode = lastNumberedEspisode.data[0];
      nextEpisodeNumber =
        (lastMonthlyEpisode.attributes?.episodeNumber || 0) + 1;
    }
    this.setState({ nextEpisodeNumber, lastMonthlyEpisode });
  };

  loadEpisodeArtwork = async () => {
    const image = this.props.episode.relationships.image;
    if (image && image.data) {
      this.setState({
        episodeArtwork: (
          await API.image.find({
            id: image.data.id,
            options: {}
          })
        ).data
      });
    }
  };

  loadChapterBindings = async () => {
    this.setState({
      chapterBindings: await getChapterBindings(this.props.episode.id)
    });
  };

  loadPlaylistTypes = async () => {
    const playlistTypes = await API.playlistType.all({
      options: {
        sort: 'title'
      }
    });
    this.setState({ playlistTypes: playlistTypes.data });
  };

  loadChapters = async () => {
    // TODO : filter attached/unattached (and potentially search text) via API
    this.setState({ loadingChapters: true }, async () => {
      this.setState({
        chapters: API.simplifyResource(
          await API.chapter.all({
            options: {
              sort: '-createdAt',
              'fields[chapter]': 'title'
            }
          })
        ),
        loadingChapters: false
      });
    });
  };

  checkForDuplicates = async (id, field, value, episode) => {
    const episodes = await API.episode.where({
      filter: {
        [field]: value
      }
    });

    //check that this episode's title doesn't exist already in this course
    if (
      field === 'title' &&
      episodes.data.length > 0 &&
      episodes.data.some(
        episodeData =>
          episodeData.relationships.course.data.id ===
          episode.relationships.course.data.id
      )
    ) {
      notify.show(
        `This episode's title "${value}" has already been used for this course. Please choose another.`,
        'error',
        5000
      );
      return true;
    }

    //url slugs must be unique - return true that we found a duplicate and we'll generate a new one
    if (field === 'urlSlug' && episodes.data.length > 0) {
      notify.show(
        `This episode's urlSlug "${value}" has already been used. 🤖 Beep beep boop...generating new urlSlug with the course shortname appended and saving`,
        'warning',
        5000
      );
      return true;
    }
    return false;
  };

  updateEpisode = async (id, field, value) => {
    try {
      const episode = _.clone(this.props.episode);
      episode.attributes[field] = value;
      const originalEpisode = await API.episode.where({
        filter: { id: episode.id }
      });

      // check if field value is the same as the original
      if (
        !originalEpisode ||
        !originalEpisode.data ||
        !originalEpisode.data[0] ||
        originalEpisode.data[0].attributes[field] === value
      ) {
        return;
      }

      // check for duplicate title or urlSlug
      if (field === 'title' || field === 'urlSlug') {
        const duplicate = await this.checkForDuplicates(
          id,
          field,
          value,
          episode
        );
        if (duplicate && field === 'title') {
          return;
        } else if (duplicate && field === 'urlSlug') {
          //if the urlSlug is duplicate - append course shortname and save
          const courseShortname = this.props.courseList.filter(
            course =>
              course.id === originalEpisode.data[0].relationships.course.data.id
          )[0].attributes.shortname;

          episode.attributes.urlSlug = value + '-' + courseShortname;
        } else if (!duplicate && field === 'title') {
          const potentialUrlSlug = value
            .replace(/[^-_\~A-Za-z0-9]/g, '-') //remove characters that need to be escaped for url
            .replace(/^-+|-+(?=-|$)/g, '') //remove any double dashes
            .toLowerCase();
          const duplicateUrlSlug = await this.checkForDuplicates(
            id,
            'urlSlug',
            potentialUrlSlug,
            episode
          );
          //if the urlSlug is duplicate - append course shortname and save
          const courseShortname = this.props.courseList.filter(
            course =>
              course.id === originalEpisode.data[0].relationships.course.data.id
          )[0].attributes.shortname;
          episode.attributes.urlSlug = !duplicateUrlSlug
            ? potentialUrlSlug
            : potentialUrlSlug + '-' + courseShortname;
        }
      }

      await API.episode.update({
        id: episode.id,
        attributes: episode.attributes
      });
      this.setState({ episode });
      this.props.onChange && this.props.onChange();
    } catch (error) {
      if (error.data) {
        notify.show(error.data.errors[0].detail[0].message, 'error');
      } else {
        throw error;
      }
    }
  };

  updateEpisodePlaylistType = async (episodeId, playlistType) => {
    await API.episode.update({
      id: episodeId,
      relationships: {
        playlistType: {
          data: playlistType
            ? {
                type: 'playlistType',
                id: playlistType
              }
            : null
        }
      }
    });
    this.setState({ selectedPlaylistType: playlistType });
    if (!this.isMonthlyPlaylistTypeSelected()) {
      this.updateEpisode(episodeId, 'episodeNumber', null);
    }
  };

  updateEpisodeArtwork = async (episodeId, image) => {
    await API.episode.update({
      id: episodeId,
      relationships: {
        image: {
          data: image
            ? {
                type: 'image',
                id: image.id
              }
            : null
        }
      }
    });
    this.setState({ episodeArtwork: image });
  };

  checkForDuplicateEpisodeTitle = async value => {
    try {
      const episodes = await API.episode.where({
        filter: {
          course: value
        }
      });
      if (
        episodes.data.find(
          episode =>
            episode.attributes.title === this.props.episode.attributes.title
        )
      ) {
        notify.show(
          'That podcast already has an episode with this title. Please choose another.',
          'error',
          5000
        );
        return true;
      }
    } catch (error) {
      if (error.data) {
        notify.show(error.data.errors[0].detail[0].message, 'error');
      } else {
        throw error;
      }
    }
  };

  updateEpisodeCourse = async (id, value) => {
    try {
      const isDuplicateEpisodeTitle = await this.checkForDuplicateEpisodeTitle(
        value
      );
      if (isDuplicateEpisodeTitle) {
        return;
      }

      await API.episode.update({
        id,
        relationships: {
          course: {
            data: {
              type: 'course',
              id: value
            }
          }
        }
      });

      const episode = _.clone(this.props.episode);
      this.updateEpisode(episode.id, 'episodeNumber', null);
      episode.relationships.course.data.id = value;
    } catch (error) {
      if (error.data) {
        notify.show(error.data.errors[0].detail[0].message, 'error');
      } else {
        throw error;
      }
    }
  };
  getLastMonthlyEpisodeTooltip = () => {
    if (this.state.lastMonthlyEpisode) {
      return (
        <div>
          Highest Numbered Episode:
          <br />
          {'#' +
            this.state.lastMonthlyEpisode.attributes.episodeNumber +
            ' ' +
            this.state.lastMonthlyEpisode.attributes.title}
          <br />
          {moment(this.state.lastMonthlyEpisode.attributes.activatedAt).format(
            'MMM DD YYYY'
          )}
        </div>
      );
    }
    return '';
  };

  isMonthlyPlaylistTypeSelected = () => {
    const selectedPlaylist = this.state.playlistTypes.find(
      playlistType => playlistType.id === this.state.selectedPlaylistType
    );
    return (
      selectedPlaylist &&
      selectedPlaylist.attributes.shortname === 'monthly-playlist'
    );
  };

  createChapter = async () => {
    try {
      const title = 'New Chapter';
      await API.chapter.create({
        attributes: {
          title,
          description: '',
          urlSlug: this.props.getSlug(null, title, this.state.chapters),
          allowComments: true,
          hasCme: this.isMonthlyPlaylistTypeSelected()
        },
        relationships: {
          image: {
            data: {
              type: 'image',
              id: config.defaults.defaultChapterImageId
            }
          }
        }
      });
      this.loadChapters();
      this.setState({ showAddChapter: true });
    } catch (error) {
      this.setState({ error });
    }
  };

  addChapter = async chapterIds => {
    this.setState({ showAddChapter: false });
    if (!chapterIds) {
      return;
    }

    const baseIndex = this.state.chapterBindings.length * 10;

    await Promise.all(
      chapterIds.map(id =>
        API.episodeChapterBinding.create({
          attributes: {
            createdAt: new Date(),
            updatedAt: new Date(),
            displayOrder: baseIndex
          },
          relationships: {
            episode: {
              data: {
                id: this.props.episode.id,
                type: 'episode'
              }
            },
            chapter: { data: { id, type: 'chapter' } }
          }
        })
      )
    );
    this.loadChapterBindings();
  };

  removeChapter = async bindingId => {
    await API.episodeChapterBinding.delete({ id: bindingId });
    this.loadChapterBindings();
  };

  onChapterDragEnd = async dropEvent => {
    if (!dropEvent.destination) {
      return;
    }
    try {
      let { chapterBindings } = this.state;
      const removedItem = chapterBindings.splice(dropEvent.source.index, 1);
      chapterBindings.splice(dropEvent.destination.index, 0, removedItem[0]);

      chapterBindings = await Promise.all(
        chapterBindings.map(async (binding, index) => {
          binding.displayOrder = index;
          await API.episodeChapterBinding.update({
            id: binding.id,
            attributes: { displayOrder: index * 10 }
          });
          return binding;
        })
      );
      this.setState({ chapterBindings });
    } catch (error) {
      this.setState({ error });
    }
  };

  stopEditing = () => {
    this.props.history.push(
      `/content/episode/${this.props.episode.id}/${this.state.editingChapter.id}`
    );
    this.props.history.replace(`/content/episode/${this.props.episode.id}`);
    this.setState({ editingChapter: null });
  };

  copyChaperSlugsToClipboard = () => {
    const course =
      this.props.courseList.find(
        c => c.id === this.props.episode.relationships.course.data.id
      )?.attributes.title || '';
    const releaseDate = this.props.episode.attributes.activatedAt;
    const episodeTitle = this.props.episode.attributes.title;
    const episodeSlug = this.props.episode.attributes.urlSlug;

    const incompleteContentSummary = [
      'course,release date,episode title,episode slug,displayOrder,chapter,chapter slug',
      ...this.state.chapterBindings.map(binding =>
        [
          course,
          releaseDate,
          episodeTitle.replace(',', '\\,'), //escape commas in titles
          episodeSlug,
          binding.displayOrder,
          binding.chapter.title.replace(',', '\\,'),
          binding.chapter.urlSlug
        ].join(',')
      )
    ].join('\n');

    navigator.clipboard.writeText(incompleteContentSummary);
    notify.show('Copied incomplete content summary to clipboard');
  };
  render() {
    if (this.state.error !== null) {
      throw this.state.error;
    }
    const { episode, courseList } = this.props;

    function editingChapterBreadcrumb(chapterId, onClick) {
      return [
        BreadcrumbConstants.HippoAdmin,
        BreadcrumbConstants.Episode,
        {
          to: `/content/episode/${episode.id}`,
          title: episode.attributes.title,
          onClick
        },
        { title: 'Chapter' }
      ];
    }

    return this.state.editingChapter ? (
      <FillMainView
        onClose={this.stopEditing}
        closeButtonText="Back to Episode"
        breadcrumbData={editingChapterBreadcrumb(
          this.state.editingChapter.id,
          this.stopEditing
        )}
      >
        <ApolloConsumer>
          {client => (
            <Chapter
              client={client}
              chapterId={this.state.editingChapter}
              getSlug={this.props.getSlug}
              updateEpisodeBindings={this.loadChapterBindings}
              courseId={episode.relationships.course.data.id}
              chapterBindings={this.state.chapterBindings}
              onBack={this.stopEditing}
            />
          )}
        </ApolloConsumer>
      </FillMainView>
    ) : (
      <div className="episode">
        <div className="episode__title-display">
          <Label>Episode: {episode.attributes.title}</Label>
        </div>
        <div className="episode__podcast-select">
          <Label>Podcast</Label>
          <Select
            placeholder="Select Podcast"
            defaultValue={episode.relationships.course.data.id}
            value={episode.relationships.course.data.id}
            onSelect={async value => {
              await this.updateEpisodeCourse(episode.id, value);
              this.loadNextEpisodeNumber();
            }}
          >
            {courseList.map(course => (
              <Select.Option key={course.id}>
                {course.attributes.title}
              </Select.Option>
            ))}
          </Select>
        </div>

        <div className="episode__title">
          <Label>Episode Title</Label>
          <EditableLabel
            defaultValue={episode.attributes.title}
            onChange={value =>
              this.updateEpisode(episode.id, 'title', value.trim())
            }
          />
        </div>

        <div className="episode__short-name">
          <Label>Episode Shortname/Short URL</Label>
          <EditableLabel
            defaultValue={episode.attributes.urlSlug}
            onChange={value =>
              this.updateEpisode(episode.id, 'urlSlug', value.trim())
            }
          />
        </div>

        <div className="episode__playlist-type">
          <Label>Playlist Type</Label>
          <Select
            placeholder="Select Playlist Type"
            defaultValue={this.state.selectedPlaylistType}
            onSelect={value => {
              this.updateEpisodePlaylistType(episode.id, value);
              this.loadNextEpisodeNumber();
            }}
          >
            {this.state.playlistTypes.map(playlistType => (
              <Select.Option key={playlistType.id}>
                {playlistType.attributes.title}
              </Select.Option>
            ))}
          </Select>
        </div>

        <div className="episode__release-date">
          <Label>Release Date</Label>
          <DatePicker
            className="episode__release-date-picker"
            defaultValue={moment(episode.attributes.activatedAt)}
            allowClear={false}
            onChange={value =>
              this.updateEpisode(
                episode.id,
                'activatedAt',
                value
                  .tz(process.env.REACT_APP_DISPLAY_TIMEZONE)
                  .startOf('day')
                  .add({ hours: 7 })
              )
            }
          />
        </div>

        <div className="episode__episode-number">
          <Label>Episode Number</Label>
          <EditableLabel
            defaultValue={episode.attributes.episodeNumber}
            onChange={value =>
              this.updateEpisode(episode.id, 'episodeNumber', value || null)
            }
          />
          <div className="episode__episode-number__button-group">
            {this.isMonthlyPlaylistTypeSelected() ? (
              <Tooltip title={this.getLastMonthlyEpisodeTooltip()}>
                <Button
                  icon={<CalculatorOutlined />}
                  size="small"
                  onClick={() =>
                    this.updateEpisode(
                      episode.id,
                      'episodeNumber',
                      this.state.nextEpisodeNumber
                    )
                  }
                >
                  {`Set Next: ${this.state.nextEpisodeNumber}`}
                </Button>
              </Tooltip>
            ) : null}
            <Button
              icon={<CloseSquareOutlined />}
              size="small"
              onClick={() =>
                this.updateEpisode(episode.id, 'episodeNumber', null)
              }
            >
              Clear
            </Button>
          </div>
        </div>

        <div className="episode__artwork">
          <Label>Episode Artwork </Label>
          <SingleImageInput
            name="artwork"
            value={this.state.episodeArtwork}
            onChange={image => {
              this.updateEpisodeArtwork(episode.id, image);
            }}
            path="rap/artwork"
          />
        </div>

        <div className="episode__summary">
          <Label>Episode Summary</Label>
          <RichTextEditor
            className="episode__description"
            defaultValue={episode.attributes.description}
            onChange={value => {
              this.updateEpisode(episode.id, 'description', value);
            }}
          />
        </div>

        <div className="episode__cme">
          <Label>CME </Label>
          <Button
            icon={<PlusOutlined />}
            size="small"
            className="episode__cme-manage-button"
            onClick={() =>
              this.props.history.push(`/content/episode/${episode.id}/cme`)
            }
          >
            Manage CME
          </Button>
        </div>

        <Label>Audio Files</Label>
        <AudioUpload
          newChapterHasCme={this.isMonthlyPlaylistTypeSelected()}
          onChange={chapter => this.addChapter([chapter.id])}
        />

        <div className="episode__chapter-list-container">
          <Label>Chapters </Label>
          <ChapterListItemHeader />
          <SortableList
            itemType="chapters"
            items={this.state.chapterBindings}
            className="episode__chapter-list"
            onDragEnd={this.onChapterDragEnd}
            renderItem={(resourceItem, i) => (
              <ChapterListItem
                chapter={resourceItem.chapter}
                index={i}
                refresh={() => this.loadChapterBindings()}
                removeItem={() => this.removeChapter(resourceItem.id)}
                onClickItem={val => {
                  this.props.history.push(
                    `/content/episode/${episode.id}/${val}`
                  );
                  this.setState({ editingChapter: val });
                }}
              />
            )}
          />
          <Card className="episode__chapter-list__footer">
            <Button
              type="primary"
              icon={<PlusOutlined />}
              size="small"
              onClick={() => this.setState({ showAddChapter: true })}
            >
              Add Existing Chapters
            </Button>
            <Button
              icon={<PlusOutlined />}
              size="small"
              onClick={() => this.createChapter()}
            >
              Create A New Chapter
            </Button>
            <Button
              icon={<CopyOutlined />}
              size="small"
              onClick={() => this.copyChaperSlugsToClipboard()}
            >
              Copy Url Slugs
            </Button>
          </Card>
          <ResourceSelectorModal
            title="Chapters"
            apiResource={API.chapter}
            defaultOptions={{
              include:
                'chapterFacultyBindings.faculty,chapterEditorBindings.faculty,chapterTagBindings.tag'
            }}
            renderHeader={() => <ChapterListItemHeader />}
            searchPlaceholder="Search titles"
            searchAction={(actions, value) =>
              actions.setFilter('title', value && `:${value}`)
            }
            renderItem={(index, item, isSelected, onClick) => (
              <ChapterListItem
                chapter={item}
                index={index}
                selectable={true}
                isSelected={isSelected}
                onClickItem={onClick}
                hideDate={true}
              />
            )}
            disabledIds={
              this.state.chapterBindings &&
              this.state.chapterBindings.map(binding => binding.chapter.id)
            }
            visible={this.state.showAddChapter}
            okButtonText="Add Selected Chapters to Episode"
            onSelect={this.addChapter}
            onClose={() => this.setState({ showAddChapter: false })}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(Episode);
