import React from 'react';
import { Tooltip } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

/**
 * Lockable field
 * @see {@link ../Item/ItemFields/SkuField.jsx} for example usage
 * @param {{
 *   locked: boolean;
 *   onUnlock: () => void;
 *   children: React.ReactNode;
 * }} props
 */
export const LockableField = ({ locked, onUnlock, label }) => (
  <div className="lock-label" style={{ display: 'flex', alignItems: 'center' }}>
    <span>{label}</span>
    {locked && (
      <Tooltip title="Click to edit">
        <LockOutlined
          aria-label="Unlock field for editing"
          onClick={onUnlock}
          style={{ display: 'inline-block' }}
        />
      </Tooltip>
    )}
  </div>
);

LockableField.propTypes = {
  locked: PropTypes.bool.isRequired,
  onUnlock: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired
};
