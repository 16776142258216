import * as API from '../../API';

const processData = async (
  courseId,
  courseLoaded,
  data,
  rowKeysBeingRepliedTo,
  currentUserAccount,
  currentFaculty
) => {
  if (data) {
    const returnObj = {};
    if (courseId && !courseLoaded) {
      returnObj.setCourseLoaded = true;
      const commentsResult = data;
      if (!data) {
        returnObj.setCourseLoaded = false;
      } else {
        returnObj.setCourseLoaded = true;
      }

      const courseResult = await API.course.find({
        id: courseId,
        options: {
          include:
            'courseModeratorBindings,courseModeratorBindings.faculty,courseType'
        }
      });

      const getTags = commentRecord => {
        const tags = [];
        if (commentRecord.status.toLowerCase() === 'pending') {
          const tag = {
            name: 'Unpublished',
            color: 'pink'
          };
          tags.push(tag);
        }
        // test to support multiple tags in the future
        //tags.push({ name: 'Test', color: 'blue' });
        return tags;
      };

      const getActionButtonText = comment => {
        let returnText;
        if (rowKeysBeingRepliedTo.includes(comment.id)) {
          returnText = 'Cancel';
        } else {
          if (comment.status.toLowerCase() === 'pending') {
            returnText = 'Publish';
          } else {
            const adminCanedit = currentUserAccount === comment.account.id;
            if (adminCanedit) {
              returnText = 'Edit';
            } else {
              returnText = 'Reply';
            }
          }
        }

        return returnText;
      };
      const getMenuItems = comment => {
        const publishUnPublishActionText =
          comment.status.toLowerCase() === 'pending' ? 'Publish' : 'Unpublish';

        const markUnmarkAsFacultyActionText = comment.facultyAccountId
          ? 'Unmark Comment as Faculty'
          : 'Mark Comment as Faculty';

        const facultyButtonIsDisabled =
          data.facultiesList.filter(
            faculty => faculty.accountId === comment.account.id
          ).length === 0
            ? true
            : false;

        let viewInContextUrl = process.env.REACT_APP_WEB_HOST;
        if (comment.lecture) {
          viewInContextUrl += `/${commentsResult.course.shortname}/video/${comment.lecture.topic.urlSlug}/${comment.lecture.urlSlug}/discussion`;
        } else {
          const episodeSlug =
            comment.chapter?.episodeChapterBindingsList[0]?.episode?.urlSlug;
          if (episodeSlug) {
            viewInContextUrl += `/${commentsResult.course.shortname}/podcast/${episodeSlug}/${comment.chapter?.urlSlug}/discussion`;
          } else {
            //catchall in case of bad data
            viewInContextUrl += `/${commentsResult.course.shortname}/podcast/`;
          }
        }

        return [
          {
            title: 'Unpublish Comment',
            key: publishUnPublishActionText,
            text: publishUnPublishActionText
          },
          {
            title: 'View Comment in Context',
            key: 'ViewComment',
            text: 'View Comment in Context',
            link: viewInContextUrl
          },
          {
            title: 'Archive Comment',
            key: 'Archive',
            text: 'Archive'
          },
          {
            title: 'Mark Existing Comment as Faculty',
            key: markUnmarkAsFacultyActionText,
            text: markUnmarkAsFacultyActionText,
            disabled: facultyButtonIsDisabled,
            disabledText: 'Please set this account up as Faculty to enable this'
          }
        ];
      };
      const getCommentAlias = account => {
        return account.commentingAlias === undefined ||
          account.commentingAlias === null ||
          account.commentingAlias === ''
          ? account.firstName + ' ' + account.lastName + ' ' + account.suffix
          : account.commentingAlias;
      };
      const commentKeys = [];

      const facultyDict = {};

      commentsResult.facultiesList.forEach(faculty => {
        facultyDict[faculty.accountId] = faculty;
      });

      const getChildCommentFromChildData = childData => {
        let childCommentIsByFaculty = false;
        let subChildren = [];

        if (childData) {
          if (!commentKeys.includes(childData.id)) {
            commentKeys.push(childData.id);
          }
          childCommentIsByFaculty = childData.facultyAccountId !== null;

          if (
            childData.commentsByParentIdList &&
            childData.commentsByParentIdList.length > 0
          ) {
            subChildren = childData.commentsByParentIdList.map(subChildData => {
              if (!commentKeys.includes(subChildData.id)) {
                commentKeys.push(subChildData.id);
              }
              return getChildCommentFromChildData(subChildData);
            });
          }
        }

        return {
          key: childData.id,
          commentText: {
            tags: getTags(childData),
            bodyText: childData.bodyText
          },
          accountId: childData.account.id,
          email: childData.account.email,
          alias: getCommentAlias(childData.account),
          image: childData.account.image?.url,
          isFacultyComment: childCommentIsByFaculty,
          lectureId: childData.lecture?.id,
          episodeId:
            childData.chapter?.episodeChapterBindingsList[0].episode.id,
          chapterId: childData.chapter?.id,
          childrenComments:
            subChildren.length > 0 ? [...subChildren] : undefined,
          date: childData.createdAt,
          action: getActionButtonText(childData),
          menuItems: getMenuItems(childData),
          isBeingEdited: false,
          isBeingRepliedTo: rowKeysBeingRepliedTo.includes(childData.id)
        };
      };

      const mappedResults = commentsResult.commentsList.map(
        (commentRecord, index) => {
          if (!commentKeys.includes(commentRecord.id)) {
            commentKeys.push(commentRecord.id);
          }
          const children = commentRecord.commentsByParentIdList.map(
            childData => {
              return getChildCommentFromChildData(childData);
            }
          );
          const parentCommentIsByFaculty =
            commentRecord.facultyAccountId !== null;
          return {
            key: commentRecord.id,
            commentText: {
              tags: getTags(commentRecord),
              bodyText: commentRecord.bodyText
            },
            alias: getCommentAlias(commentRecord.account),
            image: commentRecord.account.image?.url,
            accountId: commentRecord.account.id,
            email: commentRecord.account.email,
            isFacultyComment: parentCommentIsByFaculty,
            courseTitle: commentsResult.course.title,
            contentTitle: commentRecord.lecture
              ? commentRecord.lecture.title
              : commentRecord.chapter.title,
            date: commentRecord.createdAt,
            action: getActionButtonText(commentRecord),
            menuItems: getMenuItems(commentRecord),
            childrenComments: children.length > 0 ? [...children] : undefined,
            lectureId: commentRecord.lecture?.id,
            episodeId:
              commentRecord.chapter?.episodeChapterBindingsList[0].episode.id,
            chapterId: commentRecord.chapter?.id,
            isBeingEdited: false,
            isBeingRepliedTo: rowKeysBeingRepliedTo.includes(commentRecord.id)
          };
        }
      );
      returnObj.commentKeys = commentKeys;
      returnObj.mappedResults = mappedResults;
      returnObj.courseResult = API.simplifyResource(courseResult);
      returnObj.currentFaculty = currentFaculty;
      return returnObj;
    }
  }
};

export { processData };
