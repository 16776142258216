import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  AudioTwoTone,
  MinusCircleOutlined,
  PictureFilled,
  PictureOutlined,
  DeleteOutlined,
  FileOutlined,
  FileFilled,
  CheckCircleFilled,
  CloseCircleOutlined
} from '@ant-design/icons';
import { Button, Tag, Row, Col, Checkbox } from 'antd';
import moment from 'moment';

import { formatTimeHoursMinutesSeconds } from '../../utils/time';

import './ChapterListItem.css';

export default function ChapterListItem({
  chapter,
  index,
  hideIndex,
  selectable,
  isSelected,
  removeItem,
  onClickItem,
  className,
  showDate,
  deleteItem,
  refresh
}) {
  const [tagsExpanded, setTagsExpanded] = useState(false);
  const [processing, setProcessing] = useState(
    chapter.media && chapter.media.pipelineError === 'processing'
  );

  useEffect(() => {
    if (processing) {
      const interval = setInterval(() => {
        refresh();
        if (chapter.media && chapter.media.pipelineError !== 'processing') {
          setProcessing(false);
        }
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [processing, chapter.media, refresh]);

  const toggleExpandTags = () => {
    setTagsExpanded(!tagsExpanded);
  };

  return (
    <div className={className || 'chapter-list-item'}>
      <Row gutter={[4, 0]} onClick={() => onClickItem(chapter.id)}>
        {!hideIndex && (
          <Col span={1} className="chapter-list-item__index">
            {/* If this is a selectable item, add a checkbox. Otherwise show the order this
                item is listed in. */}
            {selectable && <Checkbox checked={isSelected} />}
            {!selectable && index + 1}
          </Col>
        )}
        <Col
          span={4}
          className="chapter-list-item__title chapter-list-item__truncate-text"
        >
          {chapter.title}
        </Col>
        <Col span={2} className="chapter-list-item__duration">
          {chapter.media ? (
            <>
              <AudioTwoTone
                twoToneColor={
                  chapter.media.pipelineError
                    ? chapter.media.pipelineError === 'processing'
                      ? '#52c41a'
                      : '#eb2f96'
                    : '#000'
                }
              />
              <span>
                {formatTimeHoursMinutesSeconds(chapter.media.duration)}
              </span>
            </>
          ) : (
            <span>No Audio</span>
          )}
        </Col>
        <Col span={3} className="chapter-list-item__faculty">
          {chapter.chapterFacultyBindings.length === 0 && 'No faculty'}
          {chapter.chapterFacultyBindings
            .sort((a, b) => a.displayOrder - b.displayOrder)
            .map(
              binding =>
                binding.faculty && (
                  <Tag
                    closable={false}
                    key={binding.faculty.id}
                    className="chapter-list-item__truncate-text"
                  >
                    {binding.faculty.fullName}
                  </Tag>
                )
            )}
        </Col>
        <Col span={3} className="chapter-list-item__medical-editor">
          {chapter.chapterEditorBindings.length === 0 && 'No editor'}
          {chapter.chapterEditorBindings
            .sort((a, b) => a.displayOrder - b.displayOrder)
            .map(
              binding =>
                binding.faculty && (
                  <Tag
                    closable={false}
                    key={binding.faculty.id}
                    className="chapter-list-item__truncate-text"
                  >
                    {binding.faculty.fullName}
                  </Tag>
                )
            )}
        </Col>
        <Col
          span={3}
          className="chapter-list-item__show-notes chapter-list-item__truncate-text"
        >
          Text: {chapter.description.length} characters
          <br />
          {chapter.attachments.length > 0
            ? `${chapter.attachments.length} file(s) attached`
            : 'No files attached'}
        </Col>
        <Col span={1} className="chapter-list-item__type" align="center">
          {chapter.hasCme ? <CheckCircleFilled /> : <CloseCircleOutlined />}
        </Col>
        <Col span={1}>
          <span className="chapter-list-item__show-notes-indicator">
            {chapter.attachments.length > 0 ? <FileFilled /> : <FileOutlined />}
          </span>
        </Col>
        <Col span={1}>
          <span className="chapter-list-item__artwork-indicator">
            {chapter.image && <PictureFilled />}
            {!chapter.image && <PictureOutlined />}
          </span>
        </Col>
        <Col span={3}>
          {chapter.chapterTagBindings.length > 1 && tagsExpanded && (
            <>
              {chapter.chapterTagBindings.map(tag => (
                <Tag key={tag.id} className="chapter-list-item__truncate-text">
                  {tag.tag.title}
                </Tag>
              ))}
              <div
                onClick={e => {
                  e.stopPropagation();
                  toggleExpandTags();
                }}
                className="chapter-list-item__tag-expansion-toggle"
              >
                <Tag>x</Tag>
              </div>
            </>
          )}
          {chapter.chapterTagBindings.length > 1 && !tagsExpanded && (
            <>
              <Tag className="chapter-list-item__truncate-text">
                {chapter.chapterTagBindings[0].tag.title}
              </Tag>
              <div
                onClick={e => {
                  e.stopPropagation();
                  toggleExpandTags();
                }}
                className="chapter-list-item__tag-expansion-toggle"
              >
                <Tag>...</Tag>
              </div>
            </>
          )}
          {chapter.chapterTagBindings.length === 1 && (
            <Tag
              className="chapter-list-item__truncate-text"
              key={chapter.chapterTagBindings[0].id}
            >
              {chapter.chapterTagBindings[0].title}
            </Tag>
          )}
          {chapter.chapterTagBindings.length === 0 && 'No tags'}
        </Col>
        {showDate && (
          <Col span={1.5}>
            <span className="chapter-list-item__date">
              {chapter.createdAt
                ? moment(chapter.createdAt)
                    .tz(process.env.REACT_APP_DISPLAY_TIMEZONE)
                    .format('MMM D, YYYY')
                : 'N/A'}
            </span>
          </Col>
        )}
        {removeItem && (
          <Col span={1}>
            <span className="chapter-list-item__unlink">
              <Button
                type="danger"
                icon={<MinusCircleOutlined />}
                size="small"
                onClick={e => {
                  e.stopPropagation();
                  removeItem();
                }}
              />
            </span>
          </Col>
        )}
        {deleteItem && (
          <Col span={1}>
            <span className="chapter-list-item__delete">
              <Button
                type="danger"
                icon={<DeleteOutlined />}
                size="small"
                onClick={e => {
                  e.stopPropagation();
                  deleteItem(chapter.id, chapter.title);
                }}
              />
            </span>
          </Col>
        )}
      </Row>
    </div>
  );
}

ChapterListItem.propTypes = {
  chapter: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    image: PropTypes.shape({
      type: PropTypes.string,
      id: PropTypes.string
    }),
    media: PropTypes.shape({
      type: PropTypes.string,
      id: PropTypes.string,
      duration: PropTypes.number,
      pipelineError: PropTypes.string
    }),
    chapterFacultyBindings: PropTypes.array,
    chapterEditorBindings: PropTypes.array,
    chapterTagBindings: PropTypes.array
  }).isRequired,
  index: PropTypes.number,
  hideIndex: PropTypes.bool,
  selectable: PropTypes.bool,
  isSelected: PropTypes.bool,
  removeItem: PropTypes.func,
  onClickItem: PropTypes.func,
  className: PropTypes.string,
  showDate: PropTypes.bool,
  deleteItem: PropTypes.func,
  refresh: PropTypes.func
};
