import { gql } from '@apollo/client';

const COURSE_SALES_PAGE_QUERY = gql`
  query CourseSalesPageUrlsQuery {
    coursesList(condition: { showOnWebInternal: true }) {
      id
      salesPageUrl
    }
  }
`;

const COURSE_QUERY = gql`
  query CourseQuery($courseId: UUID!) {
    course(id: $courseId) {
      id
      accountEarnedCmesList(condition: { courseId: $courseId }, first: 1) {
        id
      }
      assessmentCourseId
      assessmentsList {
        id
        isCourseAssessment
        title
      }
      attestationCme
      courseAttachmentBindingsList(condition: { courseId: $courseId }) {
        id
        activatedAt
        attachment {
          id
          fileName
          hasAccountCmeAttempts
          key
          signedUrl
          subtitle
          title
          updatedAt
        }
        expiredAt
      }
      courseTagTypeBindingsList {
        id
        courseId
        isSecondary
        isPrimary
        tagType {
          id
          name
        }
        tagTypeId
      }
      courseType {
        id
        title
        shortname
      }
      defaultItemId
      enableForGroups
      logoImageId
      iconImageId
      media {
        id
        isPublic
        originalFileName
        posterFrame
        posterFrameUrl
        mediaEncodingsList {
          id
          title
          signedUrl
        }
      }
      product {
        id
        title
      }
      salesPageUrl
      shortname
      showInAccountMenu
      showInSubscriptionMenu
      showOnNative
      showOnNativeInternal
      showOnWeb
      showOnWebInternal
      title
    }
  }
`;

const COURSES_LIST_QUERY = gql`
  query CoursesListQuery($courseTypeId: UUID) {
    coursesList(
      condition: { showOnWebInternal: true, courseTypeId: $courseTypeId }
      orderBy: TITLE_ASC
    ) {
      id
      title
      courseType {
        id
      }
    }
  }
`;

const COURSETYPES_LIST_QUERY = gql`
  query CourseTypesListQuery($shortname: String) {
    courseTypesList(condition: { shortname: $shortname }) {
      id
      shortname
    }
  }
`;

export {
  COURSE_SALES_PAGE_QUERY,
  COURSE_QUERY,
  COURSES_LIST_QUERY,
  COURSETYPES_LIST_QUERY
};
