import React from 'react';
import format from 'date-fns/format';
import { Form } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

/**
 * Component to display Braintree plan information for an item
 * @param {{
 *   item: import('./types').Item;
 *   braintreePlan?: import('./types').BraintreePlan;
 *   braintreePlanLoading: boolean;
 * }} props
 */
export function BraintreePlan({ item, braintreePlan, braintreePlanLoading }) {
  if (braintreePlanLoading) {
    return <LoadingOutlined size={36} />;
  }

  if (!braintreePlan && !item.billingPeriod) {
    return (
      <div className="item__field-sections">
        <Form.Item>
          Select a billing period to create a Braintree plan.
        </Form.Item>
      </div>
    );
  }

  if (!braintreePlan && item.billingPeriod) {
    return (
      <div className="item__field-sections">
        <Form.Item style={{ color: 'red' }}>
          No braintree plan found. Clear billing period and select again.
        </Form.Item>
      </div>
    );
  }

  return (
    <div className="item__field-sections">
      <Form.Item>
        <strong>Plan Id:</strong>{' '}
        <a
          style={{ fontWeight: 'bold' }}
          href={`${process.env.REACT_APP_BRAINTREE_URL}plans/${item.braintreePlanId}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {braintreePlan.id}
        </a>
      </Form.Item>
      <Form.Item>
        <strong>Price:</strong> ${braintreePlan.price?.toFixed(2)}
      </Form.Item>
      <Form.Item>
        <strong>Billing Frequency:</strong> {braintreePlan.billingFrequency}{' '}
        months
      </Form.Item>
      <Form.Item>
        <strong>Billing Cycles:</strong>{' '}
        {braintreePlan.numberOfBillingCycles || 'Never expires'}
      </Form.Item>
      <Form.Item>
        <strong>Updated At:</strong>{' '}
        {format(new Date(braintreePlan.updatedAt), 'MMM d, yyyy h:mm a')}
      </Form.Item>
    </div>
  );
}
