import { gql } from '@apollo/client';

const GET_AVAILABLE_TAGS_FOR_CHAPTER = gql`
  query getAvailableTagsForChapter($itemId: UUID!) {
    chapter(id: $itemId) {
      episodeChapterBindingsList {
        episode {
          course {
            title
            courseTagTypeBindingsList {
              tagType {
                id
                name
                tagsList(orderBy: TITLE_ASC) {
                  id
                  title
                }
              }
              isSecondary
              isPrimary
            }
          }
        }
      }
      chapterTagBindingsList {
        id
        tag {
          id
          tagTypeId
          title
        }
      }
    }
  }
`;
const CREATE_CHAPTER_TAG_BINDING = gql`
  mutation CreateChapterTagBinding(
    $id: UUID!
    $createdAt: Datetime!
    $updatedAt: Datetime!
    $tagId: UUID!
    $itemId: UUID!
  ) {
    createChapterTagBinding(
      input: {
        chapterTagBinding: {
          id: $id
          createdAt: $createdAt
          updatedAt: $updatedAt
          chapterId: $itemId
          tagId: $tagId
        }
      }
    ) {
      clientMutationId
    }
  }
`;
const DELETE_CHAPTER_TAG_BINDING = gql`
  mutation DeleteChapterTagBinding($id: UUID!) {
    deleteChapterTagBinding(input: { id: $id }) {
      clientMutationId
    }
  }
`;

export const chapterActions = {
  get: GET_AVAILABLE_TAGS_FOR_CHAPTER,
  getSelectedTags: data => data?.chapter?.chapterTagBindingsList,
  getCourses: data =>
    data?.chapter?.episodeChapterBindingsList?.flatMap(
      binding => binding.episode.course
    ), //an episode may be in many courses
  create: CREATE_CHAPTER_TAG_BINDING,
  delete: DELETE_CHAPTER_TAG_BINDING
};
