import React, { useMemo } from 'react';
import {
  Checkbox,
  Descriptions,
  Tooltip,
  Row,
  Col,
  Input,
  DatePicker,
  Button
} from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import { Label } from 'reactstrap';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { useQuery, useMutation } from '@apollo/client';
import { notify } from 'react-notify-toast';

import CourseDescriptionSection from '../../components/Course/CourseDescriptionSection';
import RichTextEditor from '../../components/RichTextEditor';
import {
  CREATE_BANNER_MUTATION,
  GET_COURSE_BANNERS,
  UPDATE_BANNER_MUTATION
} from './queries/CourseBannerQueries';

import './CourseSettingsBannerSection.css';
import _ from 'lodash';

export default function CourseSettingsTagSection({
  course,
  loadingCourseData,
  refetch
}) {
  const { data, loading: loadingBanner } = useQuery(GET_COURSE_BANNERS, {
    variables: { courseId: course.id },
    skip: !loadingCourseData && !course
  });

  const [updateBannerMutation] = useMutation(UPDATE_BANNER_MUTATION);
  const [createBannerMutation] = useMutation(CREATE_BANNER_MUTATION);

  // don't use course.activeBanner here because if there's an inactive banner attached
  // we want to show it so it can be edited and made active
  // there'll be only one banner per course able to be created via the admin now
  // but the data supports multiple and this UI can be expanded to
  // schedule future banners while showing a banner
  const mostRecentBanner = useMemo(() => {
    if (course && data && !loadingBanner) {
      return data.bannerCourseBindingsList
        .toSorted(
          (a, b) =>
            new Date(b.banner.activatedAt) - new Date(a.banner.activatedAt)
        )
        ?.at(0)?.banner;
    }
  }, [course, data, loadingBanner]);

  const fromUTC = dateStringOrNull => {
    // The date is stored in UTC and DatePicker can only display
    // the browser-local timezone.
    // But we're concerned with setting the date in New York.
    // So things are about to get stupid...
    if (!dateStringOrNull) {
      return null;
    }
    // Find out what day it is in New York and destroy the timezone information
    const dateInNewYork = moment(dateStringOrNull)
      .tz('America/New_York')
      .format('YYYY-MM-DD');

    // re-parse the date (without tz info) as if we were in our current timezone
    return moment(dateInNewYork);
  };

  const midnightEST = momentDate => {
    // Datepicker has given us a date in our current timezone.
    // We want to pretend that we're in New York and store that as UTC
    return momentDate
      ? momentDate
          .startOf('day')
          .tz('America/New_York', true) // change timezone without adjusting timestamp
          .utc()
      : null;
  };

  const handleCreateBanner = async (fieldName, fieldValue) => {
    createBannerMutation({
      variables: {
        courseId: course.id,
        activatedAt: new Date(),
        bannerId: uuidv4(),
        bindingId: uuidv4()
      },
      refetchQueries: [GET_COURSE_BANNERS]
    }).catch(e => notify.show('Error creating banner: ' + e, 'error'));
  };

  const handleUpdateBanner = (fieldName, fieldValue) => {
    updateBannerMutation({
      variables: {
        ...mostRecentBanner,
        [fieldName]: fieldValue,
        id: mostRecentBanner.id
      }
    }).catch(e => notify.show('Error updating banner: ' + e, 'error'));
  };

  const debouncedTitleUpdate = _.debounce(
    title => handleUpdateBanner('title', title),
    500
  );

  return (
    <CourseDescriptionSection title="Banner">
      {course && !loadingBanner && (
        <Descriptions.Item
          className="course_banner"
          label={
            <div className="course_banner-label">
              <div>
                Notification Banner
                <Tooltip title="The banner shows at the top of the course page if enabled AND the current date is between the start and end times">
                  <InfoCircleFilled className="course__tag-system-label-icon" />
                </Tooltip>
              </div>
            </div>
          }
        >
          {!mostRecentBanner ? (
            <Button onClick={handleCreateBanner}> Create Banner</Button>
          ) : (
            <>
              <Row>
                <Label>Enabled:</Label>
                <Checkbox
                  label="Enabled"
                  checked={mostRecentBanner.enabled}
                  onChange={e =>
                    handleUpdateBanner('enabled', e.target.checked)
                  }
                />
              </Row>
              <Row gutter={[16, 8]}>
                <Col>
                  <Label>Start date:</Label>
                  <DatePicker
                    allowClear={false}
                    value={fromUTC(mostRecentBanner.activatedAt)}
                    onChange={activatedAt =>
                      handleUpdateBanner(
                        'activatedAt',
                        midnightEST(activatedAt)
                      )
                    }
                  />
                </Col>
                <Col>
                  <Label>End date (optional):</Label>
                  <DatePicker
                    value={fromUTC(mostRecentBanner.expiredAt)}
                    onChange={expiredAt =>
                      handleUpdateBanner('expiredAt', midnightEST(expiredAt))
                    }
                  />
                </Col>
              </Row>
              <Row>
                <small>
                  <em>*note: Times start and end at midnight EST.</em>
                </small>
              </Row>
              <Row>
                <Label>Title:</Label>
                <Input
                  type="text"
                  defaultValue={mostRecentBanner.title}
                  onChange={e => debouncedTitleUpdate(e.target.value)}
                />
              </Row>
              <Row>
                <Label>Body:</Label>
                <RichTextEditor
                  className="course_banner__body"
                  configurationName="restricted"
                  defaultValue={mostRecentBanner.subtitle}
                  debounceOnChange={true}
                  onChange={value => handleUpdateBanner('subtitle', value)}
                />
              </Row>
            </>
          )}
        </Descriptions.Item>
      )}
    </CourseDescriptionSection>
  );
}
