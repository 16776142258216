import { gql } from '@apollo/client';

const GET_BRAINTREE_PLAN = gql`
  query GetBraintreePlan($id: ID!) {
    braintreePlan(id: $id) {
      id
      name
      description
      price
      billingFrequency
      numberOfBillingCycles
      createdAt
      updatedAt
    }
  }
`;

export default GET_BRAINTREE_PLAN;
