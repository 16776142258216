import React from 'react';
import { Form, Row, Col, Input, DatePicker, Divider } from 'antd';
import { BraintreePlan } from './BraintreePlan';
import { PriceBilling } from './PriceBilling';
import { SkuField } from './SkuField';
import { CategoryField } from './CategoryField';

/**
 * Main component for rendering item fields
 * @param {{
 *   activeItem: import('./types').Item;
 *   isNewItem: boolean;
 *   isNewItemSku: boolean;
 *   braintreePlan?: import('./types').BraintreePlan;
 *   braintreePlanLoading: boolean;
 * }} props
 */
export default function ItemFields({
  activeItem,
  isNewItem,
  isNewItemSku,
  braintreePlan,
  braintreePlanLoading
}) {
  return (
    <Row gutter={{ sm: 16, lg: 40 }}>
      <Col sm={24} lg={12}>
        <Divider>Item Details</Divider>
        <div className="item__field-sections">
          <SkuField isNewItem={isNewItem} isNewItemSku={isNewItemSku} />
          <Form.Item
            label="Title"
            name="title"
            style={{ backgroundColor: isNewItem ? '#EFFD5F' : 'none' }}
            extra="Max length: 35 characters"
          >
            <Input maxLength="35" />
          </Form.Item>
          <CategoryField isNewItem={isNewItem} />
          <Row>
            <Form.Item
              label="Started At"
              name="startedAt"
              style={{
                backgroundColor: isNewItem ? '#EFFD5F' : 'none',
                marginRight: '2rem'
              }}
              extra="becomes active"
            >
              <DatePicker />
            </Form.Item>
            <Form.Item label="Ended At" name="endedAt" extra="no longer active">
              <DatePicker />
            </Form.Item>
          </Row>
          <Row style={{ marginTop: '2rem' }} />
        </div>
      </Col>
      <Col sm={12} lg={6}>
        <Divider>Price & Billing</Divider>
        <PriceBilling
          activeItem={activeItem}
          isNewItem={isNewItem}
          braintreePlan={braintreePlan}
          braintreePlanLoading={braintreePlanLoading}
        />
      </Col>
      <Col sm={12} lg={6}>
        <Divider>Braintree Plan</Divider>
        <BraintreePlan
          item={activeItem}
          braintreePlan={braintreePlan}
          braintreePlanLoading={braintreePlanLoading}
        />
      </Col>
    </Row>
  );
}
