/* eslint-disable no-useless-escape */
import _ from 'underscore';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { notify } from 'react-notify-toast';
import { Checkbox, Spin, List, Row, Col, Tooltip } from 'antd';
import { Label } from 'reactstrap';
import { InfoCircleOutlined } from '@ant-design/icons';
import { gql } from '@apollo/client';

import * as API from '../../API';
import AttachmentsInput from '../../components/Attachments/AttachmentFileList';
import RichTextEditor from '../../components/RichTextEditor';
import BoundItemSelector from '../../components/BoundItemSelector/BoundItemSelector';
import TagSelector from '../../components/TagSelector/TagSelector';
import EditableLabel from '../../components/EditableLabel/EditableLabel';
import SingleImageInput from '../../components/Upload/SingleImageInput';
import SortableList from '../../components/SortableList';
import AudioUpload from '../../components/Upload/AudioUpload';

import './Chapter.css';

export default class Chapter extends React.Component {
  static propTypes = {
    getSlug: PropTypes.func,
    chapterId: PropTypes.string,
    updateEpisodeBindings: PropTypes.func,
    courseId: PropTypes.string,
    client: PropTypes.object
  };

  static defaultProps = {
    chapterEpisodeBindingId: undefined
  };

  constructor(props) {
    super(props);

    this.state = {
      pendingQueries: 0,
      loading: true,
      error: null,
      chapter: undefined,
      chapterBindings: undefined,
      chapterArtwork: undefined,
      media: undefined,
      episodes: [],
      attachments: []
    };
  }

  async componentDidMount() {
    await this.loadChapter();
    await this.loadChapterEpisodeBindings();
    await this.loadChapterAttachments();
    await this.loadChapterArtwork();
    this.setState({ loading: false });
  }

  // Load chapter methods
  loadChapter = async () => {
    try {
      const chapter = await API.chapter.find({
        id: this.props.chapterId,
        options: {
          include: 'media'
        }
      });
      const media = chapter.included.find(
        includes => includes.type === 'media'
      );
      this.setState({ chapter: chapter.data, media });
    } catch (error) {
      if (error.data) {
        notify.show(error.data.errors[0].detail[0].message, 'error');
      } else {
        throw error;
      }
    }
    this.props.updateEpisodeBindings();
    await this.loadChapterBindings();
  };

  loadChapterBindings = async () => {
    try {
      const chapter = await API.chapter.find({
        id: this.props.chapterId,
        options: {
          include:
            'chapterFacultyBindings.faculty,chapterEditorBindings.faculty'
        }
      });
      this.setState({
        chapterBindings: API.simplifyResource(chapter)
      });
    } catch (error) {
      if (error.data) {
        notify.show(error.data.errors[0].detail[0].message, 'error');
      } else {
        throw error;
      }
    }
  };

  loadChapterEpisodeBindings = async () => {
    try {
      const filter = {
        chapter: this.props.chapterId
      };
      const episodeIds = (
        await API.episodeChapterBinding.where({
          filter,
          options: {}
        })
      ).data.map(binding => binding.relationships.episode.data.id);
      const episodes = [];
      for (const episodeId of episodeIds) {
        const episode = (await API.episode.find({ id: episodeId, options: {} }))
          .data;
        episodes.push(episode.attributes.title);
      }
      this.setState({ episodes });
    } catch (error) {
      if (error.data) {
        notify.show(error.data.errors[0].detail[0].message, 'error');
      } else {
        throw error;
      }
    }
  };

  loadChapterAttachments = async () => {
    const GET_CHAPTER_ATTACHMENTS = gql`
      query GetChapterAttachments($id: UUID!) {
        chapter(id: $id) {
          id
          chapterAttachmentBindingsList {
            attachment {
              id
              title
              subtitle
              isContentSummary
              activityTimeInSeconds
              fileName
              key
              signedUrl
              hasAccountCmeAttempts
            }
          }
        }
      }
    `;
    try {
      const response = await this.props.client.query({
        query: GET_CHAPTER_ATTACHMENTS,
        variables: { id: this.state.chapter.id },
        fetchPolicy: 'network-only'
      });

      if (!response.loading) {
        const attachments = response.data.chapter.chapterAttachmentBindingsList.map(
          binding => binding.attachment
        );
        this.setState({ attachments });
      }
    } catch (error) {
      notify.show(`Error creating new section: ${error.message}`, 'error');
    }
  };

  loadChapterArtwork = async () => {
    const image = this.state.chapter.relationships?.image;
    if (image && image.data) {
      this.setState({
        chapterArtwork: (
          await API.image.find({
            id: image.data.id,
            options: {}
          })
        ).data
      });
    }
  };

  // Modify chapter methods

  updateChapter = async (id, field, value) => {
    try {
      const chapter = _.clone(this.state.chapter);
      chapter.attributes[field] = value;

      //if props are set we're coming from <Episode />
      if (this.props.chapterBindings && this.props.getSlug) {
        // check for duplicate chapter titles
        if (field === 'title') {
          const isDuplicateChapterTitle = this.props.chapterBindings.find(
            item => item.chapter.title === value && item.chapter.id !== id
          );

          if (isDuplicateChapterTitle) {
            notify.show(
              'This chapter title has already been used for this Episode. Please choose another.',
              'error'
            );
            return;
          }
        }

        if (field === 'title' || field === 'urlSlug') {
          chapter.attributes.urlSlug = await this.props.getSlug(id, value);
        }
        //if props aren't set we're coming from <AudioContentView />
      } else {
        //generate the chapter shortname based off the title
        if (field === 'title') {
          chapter.attributes.urlSlug = value
            .replace(/[^-_\~A-Za-z0-9]/g, '-') //remove characters that need to be escaped for url
            .replace(/^-+|-+(?=-|$)/g, '') //remove any double dashes
            .toLowerCase();
        }
      }

      await API.chapter.update({
        id: chapter.id,
        attributes: chapter.attributes
      });
      this.loadChapter();
      notify.show(field + ' updated', 'success', 1500);
    } catch (error) {
      const errorText = error.data?.errors?.at(0)?.title || error;
      notify.show(
        'An error has occurred. ' +
          errorText +
          'Your changes to ' +
          field +
          ' have not been saved.',
        'error'
      );
    }
  };

  updateChapterArtwork = async image => {
    await API.chapter.update({
      id: this.state.chapter.id,
      relationships: {
        image: {
          data: image
            ? {
                type: 'image',
                id: image.id
              }
            : null
        }
      }
    });
    this.setState({ chapterArtwork: image });
    this.loadChapter();
  };

  reorderBinding = async (bindingName, bindingType, bindings) => {
    const chapterBindings = bindings ? bindings : this.state.chapterBindings;
    chapterBindings[bindingName] = await Promise.all(
      chapterBindings[bindingName].map(async (binding, index) => {
        binding.displayOrder = index * 10;
        await API[bindingType].update({
          id: binding.id,
          attributes: { displayOrder: index * 10 }
        });
        return binding;
      })
    );
    this.setState({ chapterBindings });
    this.loadChapter();
  };

  dropEventReorderBinding = async (dropEvent, bindingName, bindingType) => {
    if (!dropEvent.destination) {
      return;
    }
    try {
      const { chapterBindings } = this.state;
      const removedItem = chapterBindings[bindingName].splice(
        dropEvent.source.index,
        1
      );
      chapterBindings[bindingName].splice(
        dropEvent.destination.index,
        0,
        removedItem[0]
      );

      await this.reorderBinding(bindingName, bindingType, chapterBindings);
    } catch (error) {
      this.setState({ error });
    }
  };

  render() {
    if (this.state.error !== null) {
      throw this.state.error;
    }
    const {
      chapter,
      chapterBindings,
      episodes,
      attachments,
      media
    } = this.state;

    const faculty =
      chapterBindings &&
      chapterBindings.chapterFacultyBindings.sort(
        (a, b) => a.displayOrder - b.displayOrder
      );

    const editors =
      chapterBindings &&
      chapterBindings.chapterEditorBindings.sort(
        (a, b) => a.displayOrder - b.displayOrder
      );

    return this.state.loading ? (
      <>
        <Spin />
        <p>Loading...</p>
      </>
    ) : (
      <div className="chapter">
        <div className="chapter__title-display">
          <Label>Chapter: {chapter.attributes.title}</Label>
        </div>
        <div className="chapter__title">
          <Label>Chapter Title</Label>
          <EditableLabel
            defaultValue={chapter.attributes.title}
            onChange={value => this.updateChapter(chapter.id, 'title', value)}
          />
        </div>
        <div className="chapter__title">
          <Label>
            Chapter Shortname &nbsp;
            <Tooltip title="Shortnames are based on the title and are not editable">
              <InfoCircleOutlined />
            </Tooltip>
          </Label>
          <span>{chapter.attributes.urlSlug}</span>
        </div>
        <div className="chapter__title">
          <Label>Created on</Label>
          <div>
            {moment(chapter.attributes.createdAt).format('MMMM Do YYYY')}
          </div>
        </div>
        <div className="chapter__audio-selection">
          <Label>Audio File</Label>
          <div className="chapter__audio-selection-label">
            Current Audio:{' '}
            {media ? media.attributes.originalFileName : 'No audio attached'}
          </div>
          <AudioUpload
            multiple={false}
            onChange={() => this.loadChapter()}
            chapterId={chapter.id}
          />
        </div>
        <div className="chapter__faculty-selection">
          <Label>Faculty</Label>
          {faculty.length > 0 && (
            <div className="chapter__faculty-selection-display-list chapter__faculty-selection-display-list-item">
              <Row className="chapter__faculty-selection-display-list-header">
                <Col span={6}>Order</Col>
                <Col span={12}>Name</Col>
              </Row>
              <SortableList
                items={faculty}
                itemType="faculty"
                onDragEnd={e =>
                  this.dropEventReorderBinding(
                    e,
                    'chapterFacultyBindings',
                    'chapterFacultyBinding'
                  )
                }
                className="chapter__faculty-selection-display-list-sortable-list"
                renderItem={(item, index) => (
                  <div
                    key={index}
                    className="chapter__faculty-selection-display-list-item"
                  >
                    <Row>
                      <Col span={6}>{index + 1}</Col>
                      <Col span={12}>{item.faculty.fullName}</Col>
                    </Row>
                  </div>
                )}
              />
            </div>
          )}
          <BoundItemSelector
            primaryItem={chapter}
            primaryResourceType="chapter"
            secondaryResourceType="faculty"
            secondaryResourceDisplayAttribute="fullName"
            bindingResourceType="chapterFacultyBinding"
            placeholder="Add faculty"
            defaultBindingResourceAttributes={{
              displayOrder: (faculty.length + 1) * 10
            }}
            orderedDisplay={true}
            onBindingItemAdd={async () => {
              await this.loadChapter();
              await this.reorderBinding(
                'chapterFacultyBindings',
                'chapterFacultyBinding'
              );
            }}
            onBindingItemRemove={async item => {
              await this.loadChapter();
              await this.reorderBinding(
                'chapterFacultyBindings',
                'chapterFacultyBinding'
              );
            }}
          />
        </div>
        <div className="chapter__medical-editor-selection">
          <Label>Medical Editor</Label>
          {editors.length > 0 && (
            <div className="chapter__faculty-selection-display-list chapter__faculty-selection-display-list-item">
              <Row className="chapter__faculty-selection-display-list-header">
                <Col span={6}>Order</Col>
                <Col span={12}>Name</Col>
              </Row>
              <SortableList
                items={editors}
                itemType="faculty"
                onDragEnd={e =>
                  this.dropEventReorderBinding(
                    e,
                    'chapterEditorBindings',
                    'chapterEditorBinding'
                  )
                }
                className="chapter__faculty-selection-display-list-sortable-list"
                renderItem={(item, index) => (
                  <div
                    key={index}
                    className="chapter__faculty-selection-display-list-item"
                  >
                    <Row>
                      <Col span={6}>{index + 1}</Col>
                      <Col span={12}>{item.faculty.fullName}</Col>
                    </Row>
                  </div>
                )}
              />
            </div>
          )}
          <BoundItemSelector
            primaryItem={chapter}
            primaryResourceType="chapter"
            secondaryResourceType="faculty"
            secondaryResourceDisplayAttribute="fullName"
            bindingResourceType="chapterEditorBinding"
            placeholder="Add editor"
            orderedDisplay={true}
            defaultBindingResourceAttributes={{
              displayOrder: (editors.length + 1) * 10
            }}
            onBindingItemAdd={async () => {
              await this.loadChapter();
              await this.reorderBinding(
                'chapterEditorBindings',
                'chapterEditorBinding'
              );
            }}
            onBindingItemRemove={async item => {
              await this.loadChapter();
              await this.reorderBinding(
                'chapterEditorBindings',
                'chapterEditorBinding'
              );
            }}
          />
        </div>

        <div className="chapter__summary">
          <Label>Show Notes</Label>
          <RichTextEditor
            className="chapter__description"
            defaultValue={chapter.attributes.description}
            onChange={value => {
              this.updateChapter(chapter.id, 'description', value);
            }}
          />
        </div>

        <div className="chapter__show-notes-selection">
          <Label>Attachments</Label>
          <AttachmentsInput
            attachments={attachments}
            onCreateItem={async attachmentId => {
              await API.chapterAttachmentBinding.create({
                attributes: {
                  createdAt: new Date(),
                  updatedAt: new Date()
                },
                relationships: {
                  chapter: {
                    data: {
                      type: 'chapter',
                      id: chapter.id
                    }
                  },
                  attachment: {
                    data: {
                      type: 'attachment',
                      id: attachmentId
                    }
                  }
                }
              });
              await this.loadChapter();
              this.loadChapterAttachments();
            }}
            onUpdateItem={async () => {
              await this.loadChapter();
              this.loadChapterAttachments();
            }}
            onDeleteItem={async () => {
              await this.loadChapter();
              this.loadChapterAttachments();
            }}
            showCmeFields={true}
          />
        </div>
        <div className="chapter__artwork-selection">
          <Label>Artwork</Label>
          <div className="chapter__artwork">
            <SingleImageInput
              name="artwork"
              value={this.state.chapterArtwork}
              onChange={image => {
                this.updateChapterArtwork(image);
              }}
              path="rap/artwork"
            />
          </div>
        </div>
        <div className="chapter__tags">
          <Label>Tags</Label>
          <TagSelector
            primaryItemId={chapter.id}
            primaryResourceType="chapter"
            onBindingItemAdd={async () => await this.loadChapter()}
          />
        </div>
        <div className="chapter__uses">
          <List
            size="small"
            className="chapter__uses-list"
            header={<Label>Uses</Label>}
            dataSource={episodes}
            renderItem={item => <List.Item>Episode: {item}</List.Item>}
          />
        </div>
        <div className="chapter__has-cme-indicator">
          <Checkbox
            onChange={e =>
              this.updateChapter(chapter.id, 'hasCme', !e.target.checked)
            }
            checked={!chapter.attributes.hasCme}
          >
            No Cme
          </Checkbox>
        </div>
        <div className="chapter__free-episode-indicator">
          <Checkbox
            onChange={e =>
              this.updateChapter(chapter.id, 'isFree', e.target.checked)
            }
            checked={chapter.attributes.isFree}
          >
            Free Chapter?
          </Checkbox>
        </div>
      </div>
    );
  }
}
