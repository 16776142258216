import { gql } from '@apollo/client';

const GET_AVAILABLE_TAGS_FOR_LECTURE = gql`
  query getAvailableTagsForLecture($itemId: UUID!) {
    lecture(id: $itemId) {
      topic {
        topicGroup {
          course {
            title
            courseTagTypeBindingsList {
              tagType {
                id
                name
                tagsList(orderBy: TITLE_ASC) {
                  id
                  title
                }
              }
              isSecondary
              isPrimary
            }
          }
        }
      }
      lectureTagBindingsList {
        id
        tag {
          id
          tagTypeId
          title
        }
      }
    }
  }
`;
const CREATE_LECTURE_TAG_BINDING = gql`
  mutation CreateLectureTagBinding(
    $id: UUID!
    $createdAt: Datetime!
    $updatedAt: Datetime!
    $tagId: UUID!
    $itemId: UUID!
  ) {
    createLectureTagBinding(
      input: {
        lectureTagBinding: {
          id: $id
          createdAt: $createdAt
          updatedAt: $updatedAt
          lectureId: $itemId
          tagId: $tagId
        }
      }
    ) {
      clientMutationId
    }
  }
`;
const DELETE_LECTURE_TAG_BINDING = gql`
  mutation DeleteLectureTagBinding($id: UUID!) {
    deleteLectureTagBinding(input: { id: $id }) {
      clientMutationId
    }
  }
`;

export const lectureActions = {
  get: GET_AVAILABLE_TAGS_FOR_LECTURE,
  getSelectedTags: data => data?.lecture?.lectureTagBindingsList,
  getCourses: data => [data?.lecture?.topic?.topicGroup?.course],
  create: CREATE_LECTURE_TAG_BINDING,
  delete: DELETE_LECTURE_TAG_BINDING
};
