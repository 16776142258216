import React, { useEffect, useState, useCallback } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import * as API from '../../API';
import { Descriptions, Select, Tooltip, Checkbox, Collapse } from 'antd';
import { notify } from 'react-notify-toast';
import { InfoCircleFilled } from '@ant-design/icons';
import SingleImageInput from '../../components/Upload/SingleImageInput';
import CourseDescriptionSection from '../../components/Course/CourseDescriptionSection';
import CourseSettingsTagSection from './CourseSettingsTagSection';
import CourseSettingsBannerSection from './CourseSettingsBannerSection';
import deleteFromS3 from '../../utils/deleteFromS3';
import { IMAGEID_HAS_MULTIPLE_OCCURRENCES } from '../../containers/course/queries/ImageIdOccurrencesQuery';
import { UPDATE_COURSE_MUTATION } from '../../containers/course/queries/UpdateCourseMutations';
import { GET_PRODUCT_ITEMS_LIST_QUERY } from '../../containers/items/queries/GetItemsListQueries';

export default function CourseSettings({
  courseTypeShortname,
  course,
  setCourse,
  loadingCourseData,
  refetch
}) {
  const [assessmentCourses, setAssessmentCourses] = useState([]);
  const [assessmentCourseId, setAssessmentCourseId] = useState(null);
  const [iconImage, setIconImage] = useState(null);
  const [logoImage, setLogoImage] = useState(null);
  const [product, setProduct] = useState(null);
  const [productId, setProductId] = useState(null);
  const [disableCmeEarningTypeInput, setDisableCmeEarningTypeInput] = useState(
    false
  );
  const [defaultItemMessage, setDefaultItemMessage] = useState('');
  const [productItems, setProductItems] = useState([]);
  const [selectedCourseItem, setSelectedCourseItem] = useState(null);

  const cmeEarningTypes = [
    { type: 'Accrual', value: false },
    { type: 'Attestation', value: true }
  ];

  const { refetch: refetchImageIdOccurrences } = useQuery(
    IMAGEID_HAS_MULTIPLE_OCCURRENCES,
    {
      variables: {
        imageId: iconImage?.id || logoImage?.id
      },
      skip: loadingCourseData && (!iconImage || !logoImage),
      nextFetchPolicy: 'network-only'
    }
  );

  const { data: productItemsData, loading: loadingItems } = useQuery(
    GET_PRODUCT_ITEMS_LIST_QUERY,
    {
      variables: {
        productId
      },
      skip: !productId,
      nextFetchPolicy: 'network-only'
    }
  );

  const [updateCourse] = useMutation(UPDATE_COURSE_MUTATION);

  const loadData = useCallback(async course => {
    try {
      if (course.accountEarnedCmesList.length > 0) {
        setDisableCmeEarningTypeInput(true);
      }

      course.defaultItemId && setSelectedCourseItem(course.defaultItemId);

      const assessmentCourses = await API.fetchAllPages(
        API.course.where({
          filter: {
            courseType: '28937469-a900-4638-86a5-bc0dac07247d'
          }
        })
      );

      loadCourseLogoAndIcon(course.logoImageId, course.iconImageId);

      setAssessmentCourseId(course.assessmentCourseId);
      setAssessmentCourses(assessmentCourses);
      setProduct(course.product);
      setProductId(course.product.id);
    } catch (error) {
      if (error.data) {
        notify.show(error.data.errors[0].title, 'error');
      } else {
        throw error;
      }
    }
  }, []);

  useEffect(() => {
    if (selectedCourseItem && productItems?.length > 0) {
      const getDefaultItemMessage = defaultItemId => {
        const selectedItem = productItems?.find(
          item => item.id === defaultItemId
        );

        let selectedItemMessage = '';
        if (selectedItem?.isAddOn && !selectedItem?.allowStandalone) {
          selectedItemMessage +=
            'This item is an add-on and cannot be purchased standalone. ';
        }
        if (selectedItem?.pricesList.length < 1) {
          selectedItemMessage += 'This item has no price. ';
        } else if (selectedItem?.pricesList[0].price === '0.00') {
          selectedItemMessage += `Item price: $${selectedItem.pricesList[0].price}. `;
        }
        setDefaultItemMessage(selectedItemMessage);
      };

      getDefaultItemMessage(selectedCourseItem);
    }
  }, [selectedCourseItem, productItems]);

  useEffect(() => {
    if (!loadingCourseData) {
      loadData(course);
    }
    if (!loadingItems && productItemsData?.itemsList) {
      setProductItems(productItemsData?.itemsList);
    }
  }, [
    course,
    course.iconImageId,
    course.logoImageId,
    loadData,
    loadingCourseData,
    loadingItems,
    productItemsData
  ]);

  const handleDefaultCourseItemChange = async value => {
    const defaultItemId = value ?? null;
    setSelectedCourseItem(value);
    await updateCourse({
      variables: {
        id: course.id,
        defaultItemId,
        updatedAt: new Date()
      }
    });

    setCourse({ ...course, defaultItemId: value });
  };

  const loadCourseLogoAndIcon = async (logoImageId, iconImageId) => {
    if (logoImageId) {
      const logoImageData = await API.image.find({
        id: logoImageId,
        options: {}
      });
      setLogoImage(logoImageData.data);
    }
    if (iconImageId) {
      const iconImageData = await API.image.find({
        id: iconImageId,
        options: {}
      });
      setIconImage(iconImageData.data);
    }
  };

  const updateLogoImage = async image => {
    if (!image) {
      const response = await refetchImageIdOccurrences({
        imageId: logoImage.id
      });
      if (response?.data.imageIdHasMultipleOccurrences === 'false') {
        await API.image.delete({
          id: logoImage.id
        });
        deleteFromS3(course.shortname, logoImage.attributes.url);
      }
    }
    await API.course.update({
      id: course.id,
      attributes: {
        LogoImageId: image ? image.id : null
      }
    });
    setLogoImage(image);
  };

  const updateIconImage = async image => {
    if (!image) {
      const response = await refetchImageIdOccurrences({
        imageId: iconImage.id
      });
      if (response?.data.imageIdHasMultipleOccurrences === 'false') {
        await API.image.delete({
          id: iconImage.id
        });
        deleteFromS3(course.shortname, iconImage.attributes.url);
      }
    }
    await API.course.update({
      id: course.id,
      attributes: {
        IconImageId: image ? image.id : null
      }
    });
    setIconImage(image);
  };

  const handleCourseAttributeChange = async (key, value) => {
    await API.course.update({
      id: course.id,
      attributes: {
        [key]: value
      }
    });
    setCourse({ ...course, [key]: value });
  };

  const handleAssessmentCourseChange = async value => {
    const data = value
      ? {
          type: 'course',
          id: value
        }
      : null;

    await API.course.update({
      id: course.id,
      relationships: {
        assessmentCourse: {
          data
        }
      }
    });
    setAssessmentCourseId(value);
  };

  return (
    <Collapse size="large" defaultActiveKey={1}>
      <Collapse.Panel key="1" header="Course Settings">
        <Descriptions
          size="middle"
          column={1}
          bordered={true}
          style={{
            backgroundColor: '#fff',
            borderRadius: '5px'
          }}
        >
          <Descriptions.Item label="Title">
            <input
              type="text"
              value={course.title}
              onChange={e => setCourse({ ...course, title: e.target.value })}
              onBlur={e => handleCourseAttributeChange('title', e.target.value)}
              className="course__title-input"
            />
          </Descriptions.Item>
          <Descriptions.Item label="Shortname">
            {course.shortname}
          </Descriptions.Item>
          <Descriptions.Item label="Product">
            {product?.title}
          </Descriptions.Item>

          <Descriptions.Item
            label={
              <div className="course__tag-system-label">
                <div>Default Item</div>
                <Tooltip title="Select which Item to default purchases to.">
                  <InfoCircleFilled className="course__tag-system-label-icon" />
                </Tooltip>
              </div>
            }
          >
            <Select
              defaultValue={course.defaultItemId}
              placeholder="Select Default Item"
              onChange={value => handleDefaultCourseItemChange(value)}
              allowClear={true}
              className="course__select"
            >
              {productItems &&
                productItems.map(item => (
                  <Select.Option key={item.id}>
                    {`${item.sku}: ${item.title}`}
                  </Select.Option>
                ))}
            </Select>
            <p className="course__default-item-message">{defaultItemMessage}</p>
          </Descriptions.Item>
          {(courseTypeShortname === 'video' ||
            courseTypeShortname === 'podcast') && (
            <Descriptions.Item
              label={
                <div className="course__tag-system-label">
                  <div>Assessment Course</div>
                  <Tooltip title="Select which course is paired with this course as this course's assessment.">
                    <InfoCircleFilled className="course__tag-system-label-icon" />
                  </Tooltip>
                </div>
              }
            >
              <Select
                defaultValue={assessmentCourseId}
                placeholder="Select Assessment Course"
                onChange={value => handleAssessmentCourseChange(value)}
                allowClear={true}
                className="course__select"
              >
                {assessmentCourses &&
                  assessmentCourses.map(course => (
                    <Select.Option key={course.id}>
                      {course.attributes.title}
                    </Select.Option>
                  ))}
              </Select>
            </Descriptions.Item>
          )}
          {courseTypeShortname === 'video' && (
            <Descriptions.Item label="CME Earning Type">
              <Select
                defaultValue={
                  course.attestationCme ? course.attestationCme : 'Accrual'
                }
                placeholder="Select CME Earning Type"
                onChange={value =>
                  handleCourseAttributeChange('attestationCme ', value)
                }
                disabled={disableCmeEarningTypeInput}
                allowClear={false}
                className="course__select"
              >
                {cmeEarningTypes.map(cmeEarningType => (
                  <Select.Option
                    key={cmeEarningType.value}
                    value={cmeEarningType.value}
                  >
                    {cmeEarningType.type}
                  </Select.Option>
                ))}
              </Select>
              {disableCmeEarningTypeInput && (
                <div className="course_disabled-cme-menu-disaclaimer">
                  Changing CME Earning Type is not possible because users have
                  already earned CME for this course.
                </div>
              )}
            </Descriptions.Item>
          )}
          <Descriptions.Item label="Logo Image">
            <SingleImageInput
              name="logoImage"
              value={logoImage}
              onChange={image => {
                updateLogoImage(image);
              }}
              imgClassName="single-attachment-input__preview-image__logo-image"
              path={`images/logos/${course.shortname}`}
            />
          </Descriptions.Item>
          <Descriptions.Item label="Icon Image">
            <SingleImageInput
              name="iconImage"
              value={iconImage}
              onChange={image => {
                updateIconImage(image);
              }}
              imgClassName="single-attachment-input__preview-image__icon-image"
              path={`images/logos/${course.shortname}`}
            />
          </Descriptions.Item>
        </Descriptions>
        <CourseDescriptionSection title="Visibility">
          <Descriptions.Item label="Show in Subscription Menu?">
            <Checkbox
              onChange={e =>
                handleCourseAttributeChange(
                  'showInSubscriptionMenu',
                  e.target.checked
                )
              }
              checked={course.showInSubscriptionMenu}
            />{' '}
          </Descriptions.Item>
          <Descriptions.Item label="Show in Account Menu?">
            <Checkbox
              onChange={e =>
                handleCourseAttributeChange(
                  'showInAccountMenu',
                  e.target.checked
                )
              }
              checked={course.showInAccountMenu}
            />{' '}
          </Descriptions.Item>
          <Descriptions.Item label="Show on Web?">
            <Checkbox
              onChange={e =>
                handleCourseAttributeChange('showOnWeb', e.target.checked)
              }
              checked={course.showOnWeb}
            />{' '}
          </Descriptions.Item>
          <Descriptions.Item
            label={
              <div className="course__tag-system-label">
                <div>Show on Web Internal?</div>
                <Tooltip
                  title="Selecting this checkbox makes the course available for assessments, questions,
                          and orders"
                >
                  <InfoCircleFilled className="course__tag-system-label-icon" />
                </Tooltip>
              </div>
            }
          >
            <Checkbox
              onChange={e =>
                handleCourseAttributeChange(
                  'showOnWebInternal',
                  e.target.checked
                )
              }
              checked={course.showOnWebInternal}
            />{' '}
          </Descriptions.Item>
          <Descriptions.Item
            label={
              <div className="course__tag-system-label">
                <div>Enable for Groups?</div>
                <Tooltip title="Selecting this checkbox makes the course available for group subscriptions">
                  <InfoCircleFilled className="course__tag-system-label-icon" />
                </Tooltip>
              </div>
            }
          >
            <Checkbox
              onChange={e => {
                handleCourseAttributeChange(
                  'enableForGroups',
                  e.target.checked
                );
              }}
              checked={course.enableForGroups}
            />{' '}
          </Descriptions.Item>
          <Descriptions.Item label="Show on Native? (Doesn't affect anything yet)">
            <Checkbox
              onChange={e =>
                handleCourseAttributeChange('showOnNative', e.target.checked)
              }
              checked={course.showOnNative}
            />{' '}
          </Descriptions.Item>
          <Descriptions.Item label="Show on Native Internal? (Doesn't affect anything yet)">
            <Checkbox
              onChange={e =>
                handleCourseAttributeChange(
                  'showOnNativeInternal',
                  e.target.checked
                )
              }
              checked={course.showOnNativeInternal}
            />{' '}
          </Descriptions.Item>
          <Descriptions.Item label="Sales Page URL">
            <input
              type="text"
              value={course.salesPageUrl}
              onChange={e =>
                setCourse({
                  ...course,
                  salesPageUrl: e.target.value
                })
              }
              onBlur={e =>
                handleCourseAttributeChange('salesPageUrl', e.target.value)
              }
              className="course__sales-page-url-input"
            />
          </Descriptions.Item>
        </CourseDescriptionSection>

        <CourseSettingsBannerSection
          course={course}
          loadingCourseData={loadingCourseData}
          refetch={refetch}
        />

        <CourseSettingsTagSection
          course={course}
          loadingCourseData={loadingCourseData}
          refetch={refetch}
        />
      </Collapse.Panel>
    </Collapse>
  );
}
