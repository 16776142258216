import React, { useState, useMemo } from 'react';
import debounce from 'lodash/debounce';
import { Editor } from '@tinymce/tinymce-react';

/**
 * Note: TinyMCE is vendored outside the app bundle
 * in order self host and adhere to the GPL license.
 */

const TINYMCE_PATH = process.env.PUBLIC_URL + '/vendor/tinymce';
const settings = {
  // FOR FUTURE USE: If you need a different editor plugin configuraiton, add additional named configurations here.
  default: {
    height: 500,
    menubar: false,
    plugins: ['lists', 'table', 'link'],
    toolbar: [
      'undo redo | formatselect | bold italic underline superscript subscript link ' +
        '| bullist numlist outdent indent | removeformat '
    ]
    // As of TinyMCE 6.0 the paste plugin is included as a core feature of TinyMCE https://www.tiny.cloud/docs/tinymce/6/migration-from-5x/#plugins-paste

    // For further reading on a *paid* paste plugin that automatically cleans up content from Microsoft Word, Microsoft Excel, Google Docs, and HTML sources:
    // https://www.tiny.cloud/docs/tinymce/latest/introduction-to-powerpaste/
  },
  restricted: {
    height: 500,
    menubar: false,
    plugins: ['link'],
    toolbar: [
      'undo redo | bold italic underline superscript subscript link | ' +
        'removeformat '
    ]
  }
};

const RichTextEditor = ({
  className,
  defaultValue,
  onChange,
  configurationName = 'default',
  debounceOnChange = true,
  disabled = false
}) => {
  const [editorValue, setEditorValue] = useState(defaultValue);

  const onEditorChange = useMemo(
    () => (debounceOnChange && debounce(onChange, 600)) || onChange,
    [debounceOnChange, onChange]
  );

  return (
    <div className={className}>
      <Editor
        value={editorValue}
        disabled={disabled}
        tinymceScriptSrc={`${TINYMCE_PATH}/tinymce.min.js`}
        licenseKey="gpl"
        init={{
          content_css: `${TINYMCE_PATH}/skins/content/default/content.min.css`,
          ...settings[configurationName]
        }}
        onEditorChange={value => {
          setEditorValue(value);
          onEditorChange(value);
        }}
      />
    </div>
  );
};

export default RichTextEditor;
