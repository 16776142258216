import React from 'react';
import { Tooltip, message } from 'antd';
import CommentsFacultyFlair from './CommentsFacultyFlair';
import defaultAvatar from '../../images/default-avatar.png';

export default function CommentUserInfo({
  email,
  alias,
  image,
  isFacultyComment
}) {
  return (
    <Tooltip className="comments-info-icon" title={email}>
      <span
        className="comments-user"
        onClick={e => {
          navigator.clipboard.writeText(email);
          message.info('Copied ' + email + ' to clipboard');
          e.stopPropagation();
        }}
      >
        <img
          className="comments-avatar"
          src={image || defaultAvatar}
          alt="user avatar"
        />
        <div className="comments-user-detail">
          {alias}
          {isFacultyComment && <CommentsFacultyFlair />}
        </div>
      </span>
    </Tooltip>
  );
}
